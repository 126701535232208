import { createSlice } from "@reduxjs/toolkit";

export const allUsersInfoSlice = createSlice({
  name: "allUserInfo",
  initialState: [],
  reducers: {
    setAllUsersInfo: (state, action) => {
      return (state = action);
    },
  },
});

export const { setAllUsersInfo } = allUsersInfoSlice.actions;
export default allUsersInfoSlice.reducer;
