import { configureStore } from "@reduxjs/toolkit";
import { userInfoSlice } from "./getUserSlice";
import { sellerInfoSlice } from "./getSellerSlice";
import { allUsersInfoSlice } from "./getAllUsersSlice";

const store = configureStore({
  reducer: {
    userInfo: userInfoSlice.reducer,
    sellerInfo: sellerInfoSlice.reducer,
    allUsersInfo: allUsersInfoSlice.reducer,
  },
});

export default store;
