import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAllUsersInfo } from "../store/getAllUsersSlice";
import { setSellerInfo } from "../store/getSellerSlice";
import { setUserInfo } from "../store/getUserSlice";

const Protected = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [verified, setVerified] = useState(false);

  //QUERIES
  const getSeller = /* GraphQL */ `
    query GetSeller($id: ID!) {
      getSeller(id: $id) {
        id
        name
        profile
        image
        taxId
        active
        verified
        phone
        email
        website
        address
        attributes
        images
        documents
        rating
        bank
        products {
          items {
            name
            active
            createdAt
            description
            id
            image
            images
            listPrice
            verified
            unitType
            unitPrice
            tierPrice
            productCategory {
              name
              productSubCategories {
                items {
                  name
                }
              }
            }
          }
          nextToken
        }
        orders {
          items {
            id
            items
            orderStatus
          }
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  `;
  const listSellerUsers = /* GraphQL */ `
    query ListSellerUsers(
      $filter: ModelSellerUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSellerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          sellerId
          seller {
            id
            name
            profile
            image
            taxId
            active
            verified
            phone
            email
            website
            address
            attributes
            images
            documents
            rating
            bank
            products {
              items {
                name
                active
                createdAt
                description
                id
                image
                images
                listPrice
                verified
                unitType
                unitPrice
                tierPrice
                productCategory {
                  name
                  productSubCategories {
                    items {
                      name
                    }
                  }
                }
              }
              nextToken
            }
            orders {
              items {
                id
              }
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          userId
          role
          createdAt
          user {
            name
            email
            id
            phone
            role
          }
          updatedAt
        }
        nextToken
      }
    }
  `;
  //QUERIES END
  let token = localStorage.getItem("amplify-signin-with-hostedUI");

  useEffect(() => {
    let currUser = JSON.parse(localStorage.getItem("current_user"));
    let currSell = JSON.parse(localStorage.getItem("current_seller"));

    Auth.currentAuthenticatedUser()
      .then((cognito) => {
        API.graphql(
          graphqlOperation(listSellerUsers, {
            filter: { userId: { eq: cognito.username } },
          })
        )
          .then((res) => {
            if (currUser && currUser.userId === cognito.username) {
              dispatch(setUserInfo(currUser));
            } else dispatch(setUserInfo(res.data?.listSellerUsers?.items[0]));
            dispatch(setAllUsersInfo(res.data?.listSellerUsers?.items));
            API.graphql({
              query: getSeller,
              variables: {
                id: res.data?.listSellerUsers?.items[0].sellerId,
              },
            })
              .then((res) => {
                if (
                  currUser &&
                  currUser.userId === cognito.username &&
                  currSell
                ) {
                  dispatch(setSellerInfo(currSell));
                } else dispatch(setSellerInfo(res.data?.getSeller));
                if (!res.data?.getSeller?.verified) {
                  navigate("/verify");
                  setVerified(res.data.getSeller.verified);
                } else {
                  setVerified(true);
                }
              })
              .catch((e) => console.log(e, "sellerError"));
          })
          .catch((e) => {
            navigate("/login");
            console.log(e, "listError");
          });
      })
      .catch((e) => {
        console.log(e, "error");
        navigate("/login");
      });

    if (!token) navigate("/login");
  }, []);

  return <div>{token && verified && <Component />} </div>;
};

export default Protected;
