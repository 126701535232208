import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import * as React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar({ email, state, setState, text, type }) {
  const { vertical, horizontal, open } = state;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 8000);
  }, []);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
      >
        <Alert severity={type || "success"} onClose={handleClose}>
          {text} <br /> {email}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
