import { Grid, Rating } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import ImageTabs from "./ImageTabs";
import { Label } from "./styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ items }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let certificates = JSON.parse(items.documents);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >

      <h2 style={{display:"flex", justifyContent:"center", textDecoration:"underline", marginBottom:"1.5rem", marginTop:"1.5rem"}}>Product Details</h2>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Product Details" {...a11yProps(0)} />
          <Tab label="Product Documents" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Label>Product Description</Label>
          <p>{items?.description}</p>
          <Label style={{ marginBottom: "1rem" }}>Key Specification</Label>
          {items.length !== 0 &&
            JSON.parse(items?.attributes).map((item, i) => (
              <React.Fragment key={i}>
                {item.value !== "" && (
                  <Grid
                    className="fake_table"
                    key={i}
                    container
                    spacing={2}
                    style={{
                      padding: ".3rem",
                    }}
                  >
                    <Grid item xs={6} sx={{ marginBottom: ".2rem" }}>
                      {item.name}
                    </Grid>
                    <Grid item xs={6}>
                      {item.value || ""}
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
          <Label>Product Rating</Label>
          <Rating
            name="half-rating"
            defaultValue={3.4}
            precision={0.1}
            readOnly
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {certificates && certificates?.length !== 0 && (
          <>
            <h2>Product Certifications</h2>

            <ImageTabs images={certificates} />
          </>
        )}
        {certificates.length === 0 && <h2>No Certificates</h2>}
      </TabPanel>
    </Box>
  );
}
