import { Box, Card, Divider, Grid, Rating } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { GridDiv, Label, SubmitBtn } from "../components/styles";

export default function Profile() {
  const seller = useSelector((state) => state.sellerInfo.payload);
  const user = useSelector((state) => state.userInfo.payload);
  const [showAll, setShowAll] = useState(false);
  const maxLength=400;
  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <div className="base-container">
      <div className="header-container">
        <h1 className="header">Profile Details</h1>
        <Link to="/profile/edit">
          {user?.role !== "VIEWER" && <SubmitBtn>Edit</SubmitBtn>}
        </Link>
      </div>
      <Divider sx={{ mb: 3 }} />
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ mb: 5, justifyContent: "space-between" }}
        >
          <Grid item xl={8}>
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={4000}
              showThumbs={false}
            >
              <img
                src={seller?.image}
                alt="Seller Profile"
                height="400px"
                width="400px"
                style={{ objectFit: "cover" }}
              />
              {JSON.parse(seller?.images)?.gallery &&
                JSON.parse(seller?.images)?.gallery?.map((item, i) => (
                  <div key={i} style={{ height: "400px" }}>
                    {item?.type == "video" ? (
                      <video
                        controls
                        style={{ height: "100%", width: "100%" }}
                        muted
                      >
                        <source src={item?.url} />{" "}
                      </video>
                    ) : (
                      <img
                        src={item?.url}
                        alt="Seller Images"
                        style={{
                          objectFit: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    )}
                  </div>
                ))}
            </Carousel>
          </Grid>
          <Grid item xl={4}>
            <GridDiv>
              <Label>Farmer Name</Label>
              <p>{seller?.name}</p>
            </GridDiv>
            <GridDiv>
              <Label>Email ID</Label>
              <p>{seller?.email}</p>
            </GridDiv>
            <GridDiv>
              <Label>Mobile Number</Label>
              <p>{seller?.phone}</p>
            </GridDiv>
            <GridDiv>
              <Label>Registered on</Label>
              <p>{seller?.createdAt.slice(0, 10)}</p>
            </GridDiv>
            <GridDiv>
              <Label>Overall Rating</Label>
              <Rating precision={0.1}value={seller?.rating} readOnly />
            </GridDiv>
            <div>
              <Label>About Company: </Label>
              {showAll ? seller?.profile : `${seller?.profile?.substring(0, maxLength)}${seller?.profile.length > maxLength ? '...' : ''}`}
              <u style={{cursor:"pointer", color:"#183b59", fontWeight:"bold", fontStyle:"italic"}} onClick={handleToggleShowAll}>
          {showAll ?   'See Less' : 'See More'}
        </u>
            </div>
          </Grid>
        </Grid>
        {JSON.parse(seller?.images).certificates.length !== 0 && (
          <h3>Awards and Recognitions</h3>
        )}{" "}
        <Box sx={{ display: "flex", gap: 1, m: 1 }}>
          {seller?.images &&
            JSON.parse(seller?.images).certificates.map((item, i) => (
              <div key={i}>
                {item?.type === "image" ? (
                  <img
                    alt="certificates"
                    src={item?.url}
                    height="200px"
                    width="250px"
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <a href={item?.url} target="blank">
                    <img
                      alt="certificates"
                      src="/images/pdf.png"
                      height="200px"
                      width="250px"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                )}
              </div>
            ))}
        </Box>
        {seller?.documents && <h3>Registration Certificates</h3>}
        <Box sx={{ display: "flex", gap: 1, m: 1 }}>
          {seller?.documents &&
            JSON.parse(seller?.documents)?.map((item, i) => (
              <Card sx={{ p: 1, borderRadius: ".4rem" }} key={i}>
                <h4>
                  Name :{" "}
                  {item.name.substring(0, item.name.indexOf(":")) || item.name}
                </h4>
                {item.name.slice(item.name.indexOf(":")).substring(1) && (
                  <h4>
                    Number :{" "}
                    {item.name.slice(item.name.indexOf(":")).substring(1)}
                  </h4>
                )}

                {item?.type === "image" ? (
                  <img
                    src={item?.url}
                    height="200px"
                    width="250px"
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <a href={item?.url} target="blank">
                    <img
                      src="/images/pdf.png"
                      height="200px"
                      width="250px"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                )}
              </Card>
            ))}
        </Box>
      </Box>
    </div>
  );
}
