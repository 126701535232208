import { InfoRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  authCard,
  errorMsg,
  formStyle,
  Label,
  SubmitBtn,
  textStyle,
} from "../../components/styles";

const Reset = () => {
  document.title = "Reset Password | World Trade X";
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [timer, setTimer] = useState(-1);

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  // QUERIES
  const listUsers = /* GraphQL */ `
    query ListUsers(
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
  const listSellerUsers = /* GraphQL */ `
    query ListSellerUsers(
      $filter: ModelSellerUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSellerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          sellerId
          seller {
            id
            name
            profile
            image
            taxId
            active
            verified
            phone
            email
            website
            address
            attributes
            images
            documents
            rating
            bank
            products {
              items {
                name
                active
                createdAt
                description
                id
                image
                images
                listPrice
                verified
                unitType
                unitPrice
                tierPrice
                productCategory {
                  name
                  productSubCategories {
                    items {
                      name
                    }
                  }
                }
              }
              nextToken
            }
            orders {
              items {
                id
              }
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          userId
          role
          createdAt
          user {
            name
            email
            id
            phone
            role
          }
          updatedAt
        }
        nextToken
      }
    }
  `;
  //QUERIES END

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ForgotPassword = async (Email) => {
    if (!Email.includes("@")) {
      setError("Invalid Email");
      return;
    }

    setShowCode(false);
    setError(null);
    try {
      let res = await API.graphql(
        graphqlOperation(listUsers, {
          filter: { email: { eq: Email } },
        })
      );
      let userID = res.data.listUsers.items[0].id;

      if (userID) {
        let currentUser = await API.graphql(
          graphqlOperation(listSellerUsers, {
            filter: { userId: { eq: userID } },
          })
        );

        if (currentUser.data.listSellerUsers.items.length !== 0) {
          await Auth.forgotPassword(email)
            .then(() => {
              setShowCode(true);
              setTimer(30);
            })
            .catch((e) => setError(e.message));
        } else {
          setError("User doesn't exist!");
        }
      } else {
        setError("User doesn't exist!");
      }
    } catch (error) {
      console.log(error);
      setError("User doesn't exist!");
    }
  };

  const handleFormSubmit = async ({ code, password }) => {
    setOtpError(null);
    code = code.toString();
    await Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        navigate("/login");
      })
      .catch((e) => setOtpError(e.message));
    console.log(email, code, password);
  };

  return (
    <div className="auth-container">
      <Card className="session-card" sx={authCard}>
        <img
          src="/images/logo.png"
          alt="logo"
          height="70px"
          width="70px"
          style={{ alignSelf: "center" }}
        />
        <div>
          <h3 style={{ textAlign: "center", marginBottom: ".3rem" }}>
            Reset Your Password
          </h3>

          <p style={textStyle}>
            {" "}
            Already have account?{" "}
            <Link to="/login">
              <span
                style={{
                  borderBottom: "1px solid #265075",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Login
              </span>
            </Link>
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values);
            setTimeout(() => {
              setSubmitting(false);
            }, 300);
          }}
        >
          {({ isSubmitting }) => (
            <Form style={formStyle} onChange={() => setError(null)}>
              {!showCode && (
                <>
                  <Label>Email</Label>
                  <TextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="small"
                    autoComplete="off"
                    placeholder="john@yahoo.com"
                  />
                </>
              )}

              {error && <span style={errorMsg}>{error}</span>}
              {showCode && (
                <>
                  <p>
                    Code has been sent to <b>{email}</b>
                  </p>
                  <Label>Verification code*</Label>
                  <Field
                    as={TextField}
                    type="number"
                    name="code"
                    size="small"
                    autoComplete="off"
                    placeholder="***********"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    min={0}
                  />
                  <ErrorMessage style={errorMsg} name="code" component="span" />

                  {/* <Label>Password*</Label> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <Label>Password*</Label>
                    <Tooltip
                      sx={{ color: "gray", height: "15px" }}
                      title={
                        <div style={{ fontSize: ".9rem" }}>
                          <p>Password Must be atleast 8 Characters</p>
                          <p>1.One Upper Case </p>
                          <p>2.One Lower Case</p>
                          <p>3.One Number</p>
                          <p>4.One Special Character</p>
                        </div>
                      }
                    >
                      <InfoRounded />
                    </Tooltip>
                  </Box>
                  <Field
                    as={TextField}
                    id="outlined-password-input"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    size="small"
                    autoComplete="off"
                    placeholder="**********"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage
                    style={errorMsg}
                    name="password"
                    component="span"
                  />

                  <Label>Confirm Password*</Label>
                  <Field
                    as={TextField}
                    name="re_password"
                    type={showPassword ? "text" : "password"}
                    size="small"
                    autoComplete="off"
                    placeholder="**********"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <ErrorMessage
                    style={errorMsg}
                    name="re_password"
                    component="span"
                  />

                  {otpError && <p style={errorMsg}>{otpError}</p>}
                  <SubmitBtn
                    variant="contained"
                    color="secondary"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Reset password
                  </SubmitBtn>
                  <div style={textStyle}>
                    {timer > 0 && (
                      <span>
                        Resend code in <b> {timer}s</b>
                      </span>
                    )}

                    {timer === 0 && (
                      <div>
                        Didn't recieve the code ?{" "}
                        <button
                          type="button"
                          className="resendBtn"
                          onClick={() => ForgotPassword(email)}
                        >
                          Resend
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}

              {!showCode && (
                <SubmitBtn
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="button"
                  onClick={() => ForgotPassword(email)}
                >
                  Send Verification Code
                </SubmitBtn>
              )}
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

const initialValues = {
  password: "",
  re_password: "",
  code: "",
};
const formSchema = yup.object().shape({
  code: yup.number().required("Verfication code is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  re_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please re-type password")
    .matches(
      /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
});

export default Reset;
