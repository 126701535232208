import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { API } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { client } from "../App";
import ProductTable from "../components/ProductTable";
import { SubmitBtn } from "../components/styles";

const Products = () => {
  const currentUser = useSelector((state) => state);
  let sellerId = currentUser?.sellerInfo?.payload?.id;
  const user = useSelector((state) => state.userInfo?.payload);

  const autoC1 = useRef(null);
  const autoC2 = useRef(null);
  const autoC3 = useRef(null);
  const [items, setItems] = useState([]);
  const [prod, setProd] = useState([]);

  const [search, setSearch] = useState("");
  const [categories, updateCategories] = useState([]);
  const [subCategories, updateSubCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [sortByPrice, setSortByPrice] = useState("");
  const [showLoader, setShowLoader] = useState(true);

  const [value, setValue] = useState("one");
  const [disabled, setDisabled] = useState(false);
  const [active, setActive] = useState();
  const [page, setPage] = useState(0);
  // QUERIES
  const listProductCategories = /* GraphQL */ `
    query ListProductCategories(
      $filter: ModelProductCategoryFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listProductCategories(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
  const listProductSubCategories = /* GraphQL */ `
    query ListProductSubCategories(
      $filter: ModelProductSubCategoryFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listProductSubCategories(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
  // QUERIES END

  //MUTATION
  const deleteProduct = /* GraphQL */ `
    mutation DeleteProduct(
      $input: DeleteProductInput!
      $condition: ModelProductConditionInput
    ) {
      deleteProduct(input: $input, condition: $condition) {
        id
        name
        description
        image
        images
        documents
        listPrice
        unitPrice
        tierPrice
        unitType
        attributes
        active
        verified
        taxExempt
        keywords
        leadTime
        rating
        stock
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        createdAt
        productCategoryId
        productCategory {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        productSubCategoryId
        productSubCategory {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        feedbacks {
          nextToken
        }
        updatedAt
      }
    }
  `;
  const updateProduct = /* GraphQL */ `
    mutation UpdateProduct(
      $input: UpdateProductInput!
      $condition: ModelProductConditionInput
    ) {
      updateProduct(input: $input, condition: $condition) {
        id
        name
        description
        image
        images
        documents
        listPrice
        unitPrice
        tierPrice
        unitType
        attributes
        active
        verified
        taxExempt
        keywords
        leadTime
        rating
        stock
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        createdAt
        productCategoryId
        productCategory {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        productSubCategoryId
        productSubCategory {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        createdAt
        feedbacks {
          nextToken
        }
        updatedAt
      }
    }
  `;
  //MUTATION END
  function handlePriceSort(value) {
    let temp = [...items];
    temp =
      value === "Low to High"
        ? temp.sort((a, b) => a.unitPrice - b.unitPrice)
        : temp.sort((a, b) => b.unitPrice - a.unitPrice);
    setItems(temp);
  }

  function handleReset() {
    const ele1 = autoC1?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele1) ele1.click();
    const ele2 = autoC2?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele2) ele2.click();
    const ele3 = autoC3?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele3) ele3.click();

    setCategory("");
    setSubCategory("");
    setSortByPrice("");
    setSearch("");
    setItems(prod);
  }

  async function handleDelete(id) {
    API.graphql({
      query: deleteProduct,
      variables: {
        input: {
          id,
        },
      },
    })
      .then((res) => {
        let temp = [...items];
        temp = temp.filter((item) => item.id !== id);
        setItems(temp);
      })
      .catch((e) => console.log(e));
  }

  async function changeProdState(id) {
    console.log(id);
    let temp = [...items];
    let x = temp.filter((item) => item.id === id);
    API.graphql({
      query: updateProduct,
      variables: {
        input: {
          id: id,
          active: !x[0].active,
        },
      },
    })
      .then((res) => {
        x[0].active = !x[0].active;
        setItems(temp);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    setShowLoader(true);
    const debounce = setTimeout(() => {
      client
        .initIndex("product")
        .search(search, {
          facetFilters: [
            [`seller.id:${sellerId}`],
            category ? [`productCategory.name:${category}`] : "",
            subCategory ? [`productSubCategory.name:${subCategory}`] : "",
            active === false ? [`verified:${false}`] : "",
          ],
          hitsPerPage: 5,
          page: page,
        })

        .then(({ hits, nbPages }) => {
          setShowLoader(false);
          setItems(hits);
          setProd(hits);
          setDisabled(nbPages - 1 === page ? true : false);
        })
        .catch((e) => console.log(e));
    }, 400);

    return () => clearTimeout(debounce);
  }, [page, subCategory, category, active, search]);

  useEffect(() => {
    async function getCategories() {
      API.graphql({
        query: listProductCategories,
      }).then((res) => {
        let temp = [];

        res.data.listProductCategories.items
          .filter((item) => item?.active === true)
          .map((item) => {
            return temp.push(item.name);
          });
        updateCategories(temp);
        let cat = res.data.listProductCategories.items.filter(
          (item) => item.name === category
        );
        API.graphql({
          query: listProductSubCategories,
        }).then((res) => {
          let temp = res.data.listProductSubCategories.items.filter(
            (item) => item.active === true
          );
          temp = temp.filter((i) => i.productCategoryId === cat[0]?.id);
          updateSubCategories(temp.map((item) => item.name));
        });
      });
    }

    getCategories();
  }, [category]);

  return (
    <div className="base-container">
      <div className="header-container">
        <h1 className="header">Products</h1>
        {user?.role !== "VIEWER" && (
          <Link to={`/products/postProduct`}>
            <SubmitBtn>Add Product</SubmitBtn>
          </Link>
        )}
      </div>
      <Box sx={{ width: "100%", borderBottom: "1px solid #e3ebf6" }}>
        <Tabs
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          aria-label="secondary tabs example"
        >
          <Tab
            value="one"
            label="All"
            onClick={() => {
              setDisabled(false);
              setActive(null);
              handleReset();
            }}
          />
          <Tab
            value="two"
            label="Pending"
            onClick={() => {
              setPage(0);
              handleReset();
              setActive(false);
            }}
          />
        </Tabs>
      </Box>

      <div className="filter-container">
        <TextField
          autoComplete="off"
          focused
          value={search}
          onChange={(e) => {
            setPage(0);
            setSearch(e.target.value);
          }}
          size="small"
          sx={{ width: "20%" }}
          label="Type Keywords"
          placeholder="Search"
        />

        {value === "one" && (
          <>
            <Autocomplete
              ref={autoC1}
              size="small"
              disablePortal
              id="combo-box-demo"
              options={categories}
              onChange={(event, value) => {
                setPage(0);
                setCategory(value);
              }}
              sx={{ width: 130, ml: "auto" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categories"
                  focused
                  placeholder="Search"
                />
              )}
            />
            <Autocomplete
              ref={autoC2}
              size="small"
              disablePortal
              id="combo-box-demo"
              options={subCategories}
              sx={{ width: 130 }}
              onChange={(e, value) => {
                setSubCategory(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub Categories"
                  focused
                  placeholder="Search"
                />
              )}
            />

            <Autocomplete
              ref={autoC3}
              size="small"
              disablePortal
              id="combo-box-demo"
              options={["Low to High", "High to Low"]}
              sx={{ width: 130 }}
              onChange={(e, value) => handlePriceSort(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sort by Price"
                  focused
                  placeholder="Search"
                />
              )}
            />
            <SubmitBtn
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </SubmitBtn>
          </>
        )}
      </div>

      {showLoader && <CircularProgress className="loader" />}
      {!showLoader && (
        <>
          {items?.length === 0 ? (
            <div className="no-items">
              <img src="/images/noProds.svg" alt="" width="300px" />
              <h2>No Products</h2>
            </div>
          ) : (
            <ProductTable
              items={items}
              value={value}
              handleDelete={handleDelete}
              changeProdState={changeProdState}
              page={page}
              setPage={setPage}
              disabled={disabled}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Products;
