import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container">
      <div className="div-center">
        <img src="/images/logo.png" width={100} />
      </div>
      <div className="div-center">
        <h2 className="mt2 mb1">Terms and Conditions for WorldTradeX Seller</h2>
      </div>
      <div>
        <p className="normal-para ">
          These Terms and Conditions ("Agreement") govern your use of the
          WorldTradeX Ecommerce App ("App") as a seller ("Seller"). By using the
          App, you agree to be bound by this Agreement. If you do not agree to
          these terms, please do not use the App.
        </p>
        <h3 className="mt1">1. Seller Account Registration:</h3>
        <p className="normal-para mt1">
          1.1. To become a Seller on WorldTradeX, you must create an account and
          provide accurate and complete information.
        </p>
        <p className="normal-para mtSm">
          1.2. You are responsible for maintaining the confidentiality of your
          account login information and ensuring that it is secure.
        </p>
        <p className="normal-para mtSm ">
          1.3. You agree to provide true, accurate, and up-to-date information
          about yourself and your products.
        </p>
        <h3 className="mt1"> 2. Product Listings:</h3>
        <p className="normal-para mtSm ">
          2.1. As a Seller, you can list and sell products in accordance with
          WorldTradeX's guidelines and policies.
        </p>{" "}
        <p className="normal-para mtSm ">
          2.2. You are solely responsible for the accuracy and completeness of
          your product listings.
        </p>{" "}
        <p className="normal-para mtSm ">
          2.3. You must ensure that your products comply with all applicable
          laws and regulations.
        </p>{" "}
        <p className="normal-para mtSm ">
          2.4. You agree not to list prohibited or restricted items on the App.
        </p>
        <h3 className="mt1">3. Order Processing and Fulfillment:</h3>
        <p className="normal-para mtSm ">
          3.1. You agree to promptly process and fulfill orders placed by
          customers through the App.
        </p>{" "}
        <p className="normal-para mtSm ">
          3.2. You are responsible for providing accurate shipping information
          and ensuring timely delivery.
        </p>{" "}
        <p className="normal-para mtSm ">
          3.3. In case of any issues with order fulfillment or delivery, you
          must promptly notify the customer and WorldTradeX.
        </p>
      </div>
      <h3 className="mt1"> 4. Pricing and Payment:</h3>
      <p className="normal-para mtSm ">
        4.1. You have the discretion to set the prices for your products.
      </p>{" "}
      <p className="normal-para mtSm ">
        4.2. WorldTradeX will collect payments from customers on your behalf and
        transfer the funds to you after deducting applicable fees.
      </p>{" "}
      <p className="normal-para mtSm ">
        4.3. You agree to abide by WorldTradeX's payment policies and understand
        that delays or disputes may affect your payment.
      </p>
      <h3 className="mt1">5. Communication with Customers:</h3>
      <p className="normal-para mtSm ">
        5.1. You agree to maintain professional and courteous communication with
        customers.
      </p>{" "}
      <p className="normal-para mtSm ">
        5.2. You must respond to customer inquiries, requests, and complaints
        promptly and within a reasonable timeframe.
      </p>{" "}
      <p className="normal-para mtSm ">
        5.3. Any disputes or disagreements with customers should be resolved
        amicably and in good faith.
      </p>
      <h3 className="mt1">6. Seller Performance and Ratings:</h3>
      <p className="normal-para mtSm ">
        6.1. WorldTradeX may provide a performance rating system for Sellers
        based on customer feedback and other factors.
      </p>
      <p className="normal-para mtSm ">
        6.2. You acknowledge that your seller rating may affect your visibility
        and reputation on the App.
      </p>
      <p className="normal-para mtSm ">
        6.3. WorldTradeX reserves the right to take appropriate actions,
        including suspension or termination of your account, based on your
        performance.
      </p>
      <h3 className="mt1">7. Intellectual Property:</h3>
      <p className="normal-para mtSm ">
        7.1. You represent and warrant that you have the necessary rights and
        permissions to sell the products listed on the App.
      </p>
      <p className="normal-para mtSm ">
        7.2. You grant WorldTradeX a non-exclusive, worldwide, royalty-free
        license to display and promote your product listings on the App.
      </p>
      <h3 className="mt1">8. Termination:</h3>
      <p className="normal-para mtSm ">
        8.1. Either party may terminate this Agreement at any time for any
        reason.
      </p>{" "}
      <p className="normal-para mtSm ">
        8.2. WorldTradeX reserves the right to suspend or terminate your account
        if you violate any terms of this Agreement or if your actions harm the
        App or its users.
      </p>
      <h3 className="mt1">9. Limitation of Liability:</h3>
      <p className="normal-para mtSm ">
        9.1. WorldTradeX is not responsible for any losses, damages, or
        liabilities arising out of your use of the App or your interactions with
        customers.
      </p>
      <p className="normal-para mtSm ">
        9.2. In no event shall WorldTradeX be liable for any indirect,
        incidental, consequential, or punitive damages.
      </p>
      <h3 className="mt1">10. Modifications to the Agreement:</h3>
      <p className="normal-para mtSm ">
        10.1. WorldTradeX reserves the right to modify this Agreement at any
        time without prior notice.
      </p>
      <p className="normal-para mtSm ">
        10.2. It is your responsibility to review the Agreement periodically for
        any changes.
      </p>
      <p className="normal-para mtSm ">
        10.3. By continuing to use the App after any modifications, you accept
        and agree to the updated terms.
      </p>
      <h3 className="mt1">11. Governing</h3>
      <p className="normal-para mtSm ">
        Law and Dispute Resolution: 11.1. This Agreement shall be governed by
        and construed in accordance with the laws of [Jurisdiction].
      </p>
      <p className="normal-para mtSm ">
        11.2. Any disputes arising out of or in connection with this Agreement
        shall be resolved through arbitration or mediation.
      </p>
      <h3 className="mt1">12. Entire Agreement:</h3>
      <p className="normal-para mtSm ">
        12.1. This Agreement constitutes the entire understanding between you
        and WorldTradeX regarding your use of the App as a Seller and supersedes
        any prior agreements or understandings.
      </p>
      <p className="normal-para mt1 ">
        <b>
          By using the WorldTradeX Ecommerce App as a Seller, you acknowledge
          that you have read, understood, and agree to be bound by these Terms
          and Conditions.
        </b>
      </p>
    </div>
  );
};

export default TermsAndConditions;
