import { InfoRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import SnackBar from "../../components/SnackBar";
import {
  errorMsg,
  formStyle,
  Label,
  modalStyle,
  otpStyle,
  signUpStyle,
  SubmitBtn,
  textStyle,
} from "../../components/styles";
import Terms from "../../components/Terms";
import { NumericFormat } from "react-number-format";

export default function DotsMobileStepper() {
  document.title = "SignUp | WorldTradeX";
  const navigate = useNavigate();
  let countTimeOut;
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [codeError, setCodeError] = useState();
  const [timer, setTimer] = useState(-1);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [showPassword, setShowPassword] = useState(false);

  // MUTATIONS
  const createSeller = /* GraphQL */ `
    mutation CreateSeller(
      $input: CreateSellerInput!
      $condition: ModelSellerConditionInput
    ) {
      createSeller(input: $input, condition: $condition) {
        id
        name
        profile
        image
        taxId
        active
        verified
        phone
        email
        website
        address
        attributes
        images
        documents
        rating
        bank
        products {
          nextToken
        }
        orders {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  `;
  const createSellerUser = /* GraphQL */ `
    mutation CreateSellerUser(
      $input: CreateSellerUserInput!
      $condition: ModelSellerUserConditionInput
    ) {
      createSellerUser(input: $input, condition: $condition) {
        id
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        user {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        role
        createdAt
        updatedAt
      }
    }
  `;
  const createUser = /* GraphQL */ `
    mutation CreateUser(
      $input: CreateUserInput!
      $condition: ModelUserConditionInput
    ) {
      createUser(input: $input, condition: $condition) {
        id
        name
        photo
        phone
        email
        role
        deleted
        buyers {
          nextToken
        }
        sellers {
          nextToken
        }
        devices {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  `;
  // MUTATIONS END
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const formRef = useRef();
  const [email, setEmail] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    clearTimeout(countTimeOut);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (timer > 0) {
      countTimeOut = setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  const handleSendCode = async (values) => {
    setCodeError(null);
    setError(null);
    let { name, username, email, password } = values;
    name = name.trim();
    username = username.trim();
    email = email.trim();
    password = password.trim();
    setEmail(email);
    Auth.signUp({
      username: email,
      password,
      attributes: { name },
    })
      .then((cognitoData) => {
        API.graphql({
          query: createSeller,
          variables: {
            input: {
              name: name,
              email: email,
            },
          },
        })
          .then((sellerData) => {
            API.graphql({
              query: createUser,
              variables: {
                input: {
                  id: cognitoData.userSub,
                  name: username,
                  email,
                },
              },
            })
              .then((userData) => {
                API.graphql({
                  query: createSellerUser,
                  variables: {
                    input: {
                      sellerId: sellerData?.data?.createSeller?.id,
                      userId: userData?.data?.createUser?.id,
                      role: "OWNER",
                    },
                  },
                })
                  .then((sellerUser) => {
                    console.log(sellerUser, "sellerUser");
                    let temp = { ...state };
                    temp.open = true;
                    setState(temp);
                    setTimer(30);
                  })
                  .catch((e) => {
                    console.log(e, "sellerUserError");
                    setActiveStep(0);
                    setError(e.message);
                  });
              })
              .catch((e) => {
                console.log(e, "userError");
                setActiveStep(0);
                e.message !== "Username cannot be empty" && setError(e.message);
              });
          })
          .catch((e) => {
            console.log(e, "cognitoError");
            setActiveStep(0);
            e.message !== "Username cannot be empty" && setError(e.message);
          });
      })
      .catch((e) => {
        console.log(e, "conginto");
        setError("Invalid Information");
        setActiveStep(0);

        API.graphql(
          graphqlOperation(listSellers, {
            filter: { email: { eq: email } },
          })
        ).then((res) => {
          if (res.data.listSellers.items.length === 0) {
            API.graphql(
              graphqlOperation(listUsers, {
                filter: { email: { eq: email } },
              })
            ).then((userInfo) => {
              if (userInfo.data.listUsers.items[0].id) {
                API.graphql({
                  query: createSeller,
                  variables: {
                    input: {
                      name: name,
                      email: email,
                    },
                  },
                }).then((sellerInfo) => {
                  API.graphql({
                    query: createSellerUser,
                    variables: {
                      input: {
                        sellerId: sellerInfo?.data?.createSeller?.id,
                        userId: userInfo.data.listUsers.items[0].id,
                        role: "OWNER",
                      },
                    },
                  })
                    .then((res) => {
                      console.log(res, "seller user");
                      setOpenModal(true);
                    })
                    .catch((e) => setError(e.message));
                });
              }
            });
          } else {
            e.message !== "Username cannot be empty" && setError(e.message);
          }
        });
      });
  };

  const handleFormSubmit = async ({ email, code, password }) => {
    code = code.toString();
    setCodeError(null);
    await Auth.confirmSignUp(email, code)
      .then((res) => {
        Auth.signIn(email, password).then(() => navigate("/"));
      })
      .catch((e) => {
        setCodeError(e.message);
      });
  };

  const ResendConfirmationCode = async (email) => {
    setTimer(30);
    await Auth.resendSignUp(email)
      .then((res) => {
        let temp = { ...state };
        temp.open = true;
        setState(temp);
      })
      .catch((e) => console.log(e));
  };

  function handleValidate(validateForm) {
    setTimeout(async () => {
      await validateForm().then((res) => {
        Object.keys(res).length === 0 && !error && handleNext();
      });
    }, 1);
  }

  return (
    <div className="auth-container">
      <Card className="session-card" sx={signUpStyle}>
        {activeStep !== 0 && (
          <button className="back-btn" onClick={handleBack}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </button>
        )}
        <img
          src="/images/logo.png"
          alt="logo"
          height="70px"
          width="70px"
          style={{
            alignSelf: "center",
            display: activeStep === 0 ? "block" : "none",
          }}
        />
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          validationSchema={activeStep === 0 ? formSchema0 : formSchema1}
          onSubmit={() => console.log("success")}
        >
          {({
            validateForm,
            setErrors,
            setFieldTouched,
            values,
            setFieldValue,
          }) => (
            <Form
              style={formStyle}
              onChange={() => {
                setCodeError(null);
                setError(null);
              }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSendCode(values);
                handleValidate(validateForm, setErrors, setFieldTouched);
              }}
            >
              {activeStep === 0 && (
                <>
                  <h3 style={textStyle}>Welcome to World Trade X</h3>
                  <p style={textStyle}>
                    {" "}
                    Already have account?{" "}
                    <Link to="/login">
                      <span
                        style={{
                          borderBottom: "1px solid #265075",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Login
                      </span>
                    </Link>
                  </p>
                  <Label>Business Name*</Label>
                  <Field
                    required
                    as={TextField}
                    type="text"
                    name="name"
                    size="small"
                    autoComplete="off"
                    placeholder="Farm Land Ltd."
                  />
                  <ErrorMessage style={errorMsg} name="name" component="span" />
                  <Label>Your Name*</Label>
                  <Field
                    required
                    as={TextField}
                    type="text"
                    name="username"
                    size="small"
                    autoComplete="off"
                    placeholder="John"
                  />
                  <ErrorMessage
                    style={errorMsg}
                    name="username"
                    component="span"
                  />
                  <Label>Email ID*</Label>
                  <Field
                    required
                    as={TextField}
                    type="email"
                    name="email"
                    size="small"
                    autoComplete="off"
                    placeholder="john@yahoo.com"
                  />
                  <ErrorMessage
                    style={errorMsg}
                    name="email"
                    component="span"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <Label>Password*</Label>
                    <Tooltip
                      sx={{ color: "gray", height: "15px" }}
                      title={
                        <div style={{ fontSize: ".9rem" }}>
                          <p>Password Must be atleast 8 Characters</p>
                          <p>1.One Upper Case </p>
                          <p>2.One Lower Case</p>
                          <p>3.One Number</p>
                          <p>4.One Special Character</p>
                        </div>
                      }
                    >
                      <InfoRounded />
                    </Tooltip>
                  </Box>
                  <Field
                    required
                    as={TextField}
                    id="outlined-password-input"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    size="small"
                    autoComplete="off"
                    placeholder="**********"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage
                    style={errorMsg}
                    name="password"
                    component="span"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".2rem",
                    }}
                  >
                    <Label>Confirm Password*</Label>
                    <Tooltip
                      sx={{ color: "gray", height: "15px" }}
                      title={
                        <div style={{ fontSize: ".9rem" }}>
                          <p>Password Must be atleast 8 Characters</p>
                          <p>1.One Upper Case </p>
                          <p>2.One Lower Case</p>
                          <p>3.One Number</p>
                          <p>4.One Special Character</p>
                        </div>
                      }
                    >
                      <InfoRounded />
                    </Tooltip>
                  </Box>
                  <Field
                    required
                    as={TextField}
                    type={showPassword ? "text" : "password"}
                    name="re_password"
                    size="small"
                    autoComplete="off"
                    placeholder="**********"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage
                    style={errorMsg}
                    name="re_password"
                    component="span"
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Field
                      type="checkbox"
                      as={Checkbox}
                      name="agreement"
                      required
                    />
                    <Terms setFieldValue={setFieldValue} />
                  </Box>
                  <ErrorMessage
                    style={errorMsg}
                    name="agreement"
                    component="span"
                  />
                  {error && <p style={errorMsg}>{error}</p>}
                </>
              )}

              {activeStep === 1 && (
                <>
                  <Label>Verification Code </Label>
                  <span>Mail has been sent to {email}</span>
                  <Field
                    as={NumericFormat}
                    type="text"
                    name="code"
                    size="small"
                    autoComplete="off"
                    placeholder="Enter the 6 Digit PIN sent to your Email"
                    style={otpStyle}
                    maxLength={6}
                  />
                  <ErrorMessage style={errorMsg} name="code" component="span" />

                  {error && <p style={errorMsg}>{error}</p>}
                  {codeError && <p style={errorMsg}>{codeError}</p>}
                  <SubmitBtn
                    variant="contained"
                    color="secondary"
                    fullWidth
                    type="button"
                    onClick={() => handleFormSubmit(formRef.current.values)}
                  >
                    Submit
                  </SubmitBtn>

                  <div style={textStyle}>
                    {timer > 0 && (
                      <span>
                        Resend code in <b> {timer}s</b>
                      </span>
                    )}

                    {timer === 0 && (
                      <div>
                        Didn't recieve the code ?{" "}
                        <button
                          type="button"
                          className="resendBtn"
                          onClick={() =>
                            ResendConfirmationCode(formRef.current.values.email)
                          }
                        >
                          Resend
                        </button>
                      </div>
                    )}
                  </div>
                  {/* <p style={textStyle}>
                    Didn't recieve the code ?{" "}
                    <button
                      type="button"
                      className="resendBtn"
                      onClick={() =>
                        ResendConfirmationCode(formRef.current.values.email)
                      }
                    >
                      Resend
                    </button>
                  </p> */}
                </>
              )}

              {activeStep === 0 && (
                <button type="submit" className="code-btn">
                  Next
                </button>
              )}
            </Form>
          )}
        </Formik>
      </Card>
      <SnackBar
        text={"Code has been sent to"}
        email={email}
        state={state}
        setState={setState}
      />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h3 style={{ textAlign: "center" }}>
            Hi , looks like you already have a buyer account with us. Kindly use
            the same credentials to login here.
          </h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SubmitBtn onClick={() => navigate("/login")}>Login</SubmitBtn>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const initialValues = {
  name: "",
  email: "",
  username: "",
  // tell: "",
  code: "",
  password: "",
  re_password: "",
  agreement: false,
};

const formSchema0 = yup.object().shape({
  name: yup.string().required("Business Name is required"),
  username: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("invalid email")
    .required("Business Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  re_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password does not match"),
  agreement: yup
    .bool()
    .test(
      "agreement",
      "You have to agree with our Terms and Conditions!",
      (value) => value === true
    )
    .required("You have to agree with our Terms and Conditions!"),
});

const formSchema1 = yup.object().shape({
  code: yup.string().required("Code is required"),
});

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const listSellers = /* GraphQL */ `
  query ListSellers(
    $filter: ModelSellerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;
