/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://7ht535frvvgl3mjbgi2qyliuwq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-63hcgqaoyrfkphgye7se4v4sfe",
    "aws_cloud_logic_custom": [
        {
            "name": "stripe",
            "endpoint": "https://6qrlmemg08.execute-api.ap-south-1.amazonaws.com/develop",
            "region": "ap-south-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-south-1:7bfce2f0-9712-4560-86e8-832b65c73234",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_phE0MAT6n",
    "aws_user_pools_web_client_id": "4rnbch8nqgfl0kc8jgr8omhap8",
    "oauth": {
        "domain": "wtx-dev.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000,https://buy.dev.worldtradex.com,worldtradex://",
        "redirectSignOut": "http://localhost:3000,https://buy.dev.worldtradex.com,worldtradex://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "backend-storage-55a34b48130333-develop",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
