import { Delete, InfoRounded } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  CustomDiv,
  Label,
  StyledCard,
  SubmitBtn,
  UploadDiv,
  clrIcon,
  errorMsg,
  keywordDiv,
  keywordInput,
  textFieldStyle,
} from "../components/styles";

import ErrorBar from "../components/ErrorBar";

export default function StyledDropzone() {
  const regex = /^(\d*\.?\d{0,2})/;
  const navigate = useNavigate();
  const autoC = useRef(null);
  const currentUser = useSelector((state) => state);
  let sellerId = currentUser?.sellerInfo?.payload?.id;
  const user = useSelector((state) => state.userInfo?.payload);

  const [categories, updateCategories] = useState([]);
  const [subCategories, updateSubCategories] = useState([]);

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [attributes, setAttributes] = useState([
    {
      id: "87yrhksjdf",
      name: "Manufactured Date",
      value: "",
      type: "date",
      required: true,
    },
    {
      id: "76y8yfh",
      name: "Expiry Date",
      value: "",
      type: "date",
      required: true,
    },
    {
      id: "dsfadsf",
      name: "SKU",
      value: "",
      required: true,
    },
    {
      id: "989uouohk",
      name: "Brand",
      value: "",
    },

    {
      id: "76y8y8h",
      name: "Storage Type",
      value: "",
      type: "select",
    },
    {
      id: "35y8y8h",
      name: "Shelf Life",
      value: "",
      type: "select",
    },
    {
      id: "35y8ysh",
      name: "Place of Origin",
      value: "",
      type: "select",
    },
    {
      id: "35y8y44",
      name: "Product Type",
      value: "",
      type: "select",
    },
    {
      id: "h7t693",
      name: "Cultivation Type",
      value: "",
      type: "select",
    },
    {
      id: "eee4r53",
      name: "Variety",
      value: "",
      type: "select",
    },
    {
      id: "jghjhf4",
      name: "Packing",
      value: "",
      type: "select",
    },
  ]);
  const [id, setId] = useState(Math.random().toString(36).substring(2, 15));

  const [newAttr, setNewAttr] = useState({
    id: id,
    name: "",
    value: "",
    notEditable: true,
  });

  const [productName, setProductName] = useState("");
  const [productDes, setProductDes] = useState("");
  const [heroImg, setHeroImg] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");

  const [characters, updateCharacters] = useState(attributes);
  const [tax, setTax] = useState("");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [uploadError, setUploadError] = useState("");

  const [keywords, setKeyWords] = useState([]);
  const [EDT, setEDT] = useState([]);
  const [salePriceRange, setSalePriceRange] = useState(null);
  const [newPriceRange, setNewPriceRange] = useState({
    minQty: "",
    maxQty: "",
    price: "",
    discountPrice: "",
  });
  const [units, setUnits] = useState([]);
  const [unitType, setUnitType] = useState("");
  const [typeofSale, setTypeofSale] = useState("sale-by-quantity");

  const [prodCertificates, setProdCertificates] = useState([]);
  const [prodError, setProdError] = useState(null);
  const [stock, setStock] = useState("");
  const [disable, setDisable] = useState(false);

  const [dates, setDates] = useState({
    mfgDate: "",
    expDate: "",
  });

  const [openErr, setOpenErr] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [open, setOpen] = useState(false);
  // QUERIES
  const listProductCategories = /* GraphQL */ `
    query ListProductCategories(
      $filter: ModelProductCategoryFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listProductCategories(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
  const listProductSubCategories = /* GraphQL */ `
    query ListProductSubCategories(
      $filter: ModelProductSubCategoryFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listProductSubCategories(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
  // QUERIES END

  // MUTATIONS
  const createProduct = /* GraphQL */ `
    mutation CreateProduct(
      $input: CreateProductInput!
      $condition: ModelProductConditionInput
    ) {
      createProduct(input: $input, condition: $condition) {
        id
        name
        description
        image
        images
        documents
        listPrice
        unitPrice
        tierPrice
        unitType
        attributes
        active
        verified
        taxExempt
        keywords
        leadTime
        rating
        stock
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        createdAt
        productCategoryId
        productCategory {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        productSubCategoryId
        productSubCategory {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        createdAt
        feedbacks {
          nextToken
        }
        updatedAt
      }
    }
  `;
  // MUTATIONS END
  const handleAddAttribute = () => {
    setId(Math.random().toString(36).substring(2, 15));
    setError("");
    let temp = [...attributes];
    if (newAttr.name !== "" && newAttr.value !== "") {
      temp.push(newAttr);
      setAttributes(temp);
      setNewAttr({
        id: Math.random().toString(36).substring(2, 15),
        name: "",
        value: "",
        notEditable: true,
      });
    } else {
      setError("Fields are required*");
    }
  };

  const handleAddPriceRange = () => {
    setError1("");
    let temp = [...(salePriceRange || [])];
    if (
      newPriceRange.minQty !== "" &&
      newPriceRange.maxQty !== "" &&
      newPriceRange.price !== "" &&
      newPriceRange.maxQty > newPriceRange.minQty
    ) {
      temp.push(newPriceRange);
      setSalePriceRange(temp);
      setNewPriceRange({
        minQty: temp.length > 0 ? temp[temp.length - 1].maxQty + 1 : "",
        maxQty: "",
        price: "",
        discountPrice: "",
      });
    } else if (newPriceRange.maxQty <= newPriceRange.minQty) {
      setError1(
        "Minimum quantity cannot be greater than or equal to maximum quantity"
      );
    } else {
      setError1("Fields are required*");
    }
  };

  async function handleFileUpload(e) {
    if (heroImg) {
      let key = heroImg.slice(heroImg.lastIndexOf("/")).substring(1);
      Storage.remove(key);
    }
    const file = e.target.files[0];
    // if (file.size > 5242880) {
    //   setUploadError("Max File Size is 5MB");
    //   return;
    // }
    let type = file?.type.substring(0, file?.type.indexOf("/"));
    if (type !== "image") {
      setUploadError("Only Images are allowed");
      setOpenErr({ ...openErr, open: true });
      return;
    }
    setOpen(true);
    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      }
    )
      .then((result) => {
        setOpen(false);
        Storage.get(result.key, { level: "public" }).then((res) => {
          setHeroImg(res.substring(0, res.indexOf("?")));
        });
      })
      .catch((err) => {
        setOpen(false);
        setUploadError("Upload Failed, Try Different Image");
      });
  }
  async function handleFilesUpload(e, state, setState) {
    const file = e.target.files[0];
    // if (file.size > 5242880) {
    //   setUploadError("Max File Size is 15MB");
    //   setOpenErr({ ...openErr, open: true });
    //   return;
    // }
    setOpen(true);
    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      }
    )
      .then((result) => {
        Storage.get(result.key)
          .then((res) => {
            let temp1 = [...state];
            let temp = {};
            temp.id = result.key;
            temp.name = result.key;
            temp.type = file?.type.substring(0, file?.type.indexOf("/"));
            temp.url = res.substring(0, res.indexOf("?"));
            temp1.push(temp);
            setState(temp1);
            setOpen(false);
          })
          .catch((e) => console.log(e));
      })
      .catch((err) => {
        setOpen(false);
        setOpenErr({ ...openErr, open: true });
        setUploadError("Upload Failed, Try Different File");
      });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
  }

  async function handleFormSubmit() {
    API.graphql({
      query: createProduct,
      variables: {
        input: {
          sellerId,
          productCategoryId:
            categories.find((item) => item.name === category)?.id || "others",
          productSubCategoryId:
            subCategories.find((item) => item.name === subCategory)?.id ||
            "others",
          name: productName,
          description: productDes,
          image: heroImg,
          images: JSON.stringify(files),
          listPrice: Number(discountPrice),
          unitPrice: Number(actualPrice),
          tierPrice: JSON.stringify(salePriceRange),
          unitType,
          attributes: JSON.stringify(attributes),
          active: category === "" || subCategory === "" ? false : true,
          verified: category === "" || subCategory === "" ? false : true,
          taxExempt: tax === "yes" ? true : false,
          keywords,
          leadTime: EDT,
          documents: JSON.stringify(prodCertificates),
          stock,
        },
      },
    })
      .then((res) => {
        console.log(res);
        navigate("/products");
      })
      .catch((e) => {
        console.log(e);
        setProdError(e.message);
      });
  }

  useEffect(() => {
    updateCharacters(attributes);
  }, [attributes]);

  useEffect(() => {
    setAttributes(characters);
  }, [characters]);

  useEffect(() => {
    async function getCategories() {
      API.graphql(
        graphqlOperation(listProductCategories, {
          filter: { active: { eq: true } },
        })
      ).then((res) => {
        // let temp = [{ name: "Others", id: "7YT7" }];
        let temp = [];
        res.data.listProductCategories.items.map((item) => temp.push(item));
        updateCategories(temp);
        let cat = res.data.listProductCategories.items.filter(
          (item) => item.name === category
        );
        API.graphql(
          graphqlOperation(listProductSubCategories, {
            filter: { active: { eq: true } },
          })
        ).then((res) => {
          let temp = res.data.listProductSubCategories.items;
          temp = temp.filter((i) => i.productCategoryId === cat[0]?.id);
          updateSubCategories(temp.map((item) => item));
        });
      });
    }
    getCategories();
  }, [category]);

  useEffect(() => {
    const getParentEnum = `
    query GetParentEnum {
     __type(name: "UnitType") {
     enumValues {
     name
    }
    }
     }
    `;

    API.graphql(graphqlOperation(getParentEnum)).then((response) => {
      let temp = response.data.__type.enumValues.map(
        (enumValue) => enumValue.name
      );
      setUnits(temp);
    });
  }, []);

  useEffect(() => {
    +actualPrice < +discountPrice ? setDisable(true) : setDisable(false);
    if (
      (typeofSale === "sale-by-bulk" && salePriceRange === null) ||
      salePriceRange?.length === 0
    ) {
      setDisable(true);
    }
  }, [actualPrice, discountPrice, typeofSale, salePriceRange]);

  useEffect(() => {
    dates.mfgDate > dates.expDate ? setDisable(true) : setDisable(false);
  }, [dates]);

  if (user?.role === "VIEWER") return <h2>Not Authorized</h2>;

  return (
    <Box className="add-a-product-card">
      <div className="header-container">
        <div
          className="header"
          style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
        >
          <Link to="/products">
            <ArrowBackIosIcon sx={{ fontSize: "medium", marginTop: ".3rem" }} />
          </Link>
          <h1>Add a Product</h1>
        </div>
      </div>
      <Divider />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <StyledCard className="add-a-prod-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <Label>Category</Label>
            <Label>Sub Category</Label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categories.map((item) => item.name)}
              onChange={(e, value) => {
                setCategory(value);
                setSubCategory(null);
                const ele = autoC.current.getElementsByClassName(
                  "MuiAutocomplete-clearIndicator"
                )[0];
                if (ele) ele.click();
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Autocomplete
              ref={autoC}
              disablePortal
              id="combo-box-demo"
              options={subCategories.map((item) => item.name)}
              onChange={(e, value) => setSubCategory(value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <Label>Product Name*</Label>
          <TextField
            required
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value);
            }}
          />
          <Label>
            Product Hero Image*
            <Tooltip
              sx={{ color: "gray", height: "15px" }}
              title={
                <div style={{ fontSize: ".9rem" }}>
                  <p>Image Must be less than 5MB</p>
                </div>
              }
            >
              <InfoRounded />
            </Tooltip>
          </Label>

          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <input
              required
              type="file"
              accept="image/*"
              onChange={(e) => {
                handleFileUpload(e);
              }}
            />
            {heroImg && <img src={heroImg} alt="heroImg" width="60px" />}
          </div>

          <Label>
            Other Images and Videos
            <Tooltip
              sx={{ color: "gray", height: "15px" }}
              title={
                <div style={{ fontSize: ".9rem" }}>
                  <p>Image Must be less than 5MB</p>
                </div>
              }
            >
              <InfoRounded />
            </Tooltip>
          </Label>
          <div style={{ display: "flex", gap: "1rem" }}>
            {files.length < 6 && (
              <>
                <label htmlFor="image" className="img-upload">
                  <CloudUploadIcon />
                </label>
                <input
                  type="file"
                  accept="image/*,video/*"
                  hidden
                  id="image"
                  onChange={(e) => handleFilesUpload(e, files, setFiles)}
                />
              </>
            )}
            {files.map((item, index) => (
              <UploadDiv key={item.id}>
                <div key={index} style={{ height: "100px" }}>
                  {item?.type === "video" ? (
                    <video
                      width={100}
                      height={100}
                      autoPlay
                      style={{ borderRadius: "10px" }}
                      muted
                    >
                      <source src={item?.url} />{" "}
                    </video>
                  ) : (
                    <img
                      src={item?.url}
                      alt="prod images"
                      width="100px"
                      height="90px"
                      style={{
                        objectFit: "cover",
                        borderRadius: ".6rem",
                      }}
                    />
                  )}
                </div>

                <ClearIcon
                  sx={clrIcon}
                  onClick={() => {
                    Storage.remove(item?.name)
                      .then((res) => {
                        let temp = files.filter((item, i) => i !== index);
                        setFiles(temp);
                      })
                      .catch((e) => console.log(e, "error"));
                  }}
                />
              </UploadDiv>
            ))}
          </div>

          <Label>Product Description*</Label>
          <TextareaAutosize
            required
            value={productDes}
            onChange={(e) => setProductDes(e.target.value)}
            minRows={6}
            maxLength={500}
            style={textFieldStyle}
          />
          <div
            style={{
              borderRadius: ".4rem",
              border: "2px solid lightgray",
            }}
          >
            <div style={{ padding: "1.6rem" }}>
              <Label>Key Specification</Label>
              <p
                style={{
                  fontSize: ".8rem",
                  color: "lightgray",
                  marginTop: ".5rem",
                }}
              >
                Product attributes are quantifiable or descriptive aspects of a
                product (such as, color). For example, if you were to create an
                attribute for color, with the values of blue, green, yellow, and
                so on, you may want to apply this attribute to shirts, which you
                sell in various colors (you can adjust a price or weight for any
                of existing attribute values). You can add attribute for your
                product using existing list of attributes, or if you need to
                create a new one.
              </p>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <div
                      style={{ marginTop: "1.6rem" }}
                      className="characters"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {characters.map((item, index) => {
                        return (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            className="dragItem"
                          >
                            {(provided) => (
                              <div
                                className="date-box"
                                key={item.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TextField
                                  value={item.name}
                                  disabled={!item.notEditable}
                                  onChange={(e) => {
                                    let temp = [...characters];
                                    let temp2 = { ...temp[index] };
                                    temp2.name = e.target.value;
                                    temp[index] = temp2;
                                    item.notEditable && updateCharacters(temp);
                                  }}
                                />

                                {item.type === "date" ? (
                                  <TextField
                                    type="date"
                                    value={item.value}
                                    required={item.required}
                                    onChange={(e) => {
                                      if (item.name === "Manufactured Date") {
                                        let date = { ...dates };
                                        date.mfgDate = e.target.value;
                                        setDates(date);
                                      }
                                      if (item.name === "Expiry Date") {
                                        let date = { ...dates };
                                        date.expDate = e.target.value;
                                        setDates(date);
                                      }
                                      var attributeDate = e.target.value;

                                      console.log(attributeDate);
                                      let temp = [...attributes];
                                      temp[index].value = attributeDate;
                                      setAttributes(temp);
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    value={item.value}
                                    required={item.required}
                                    onChange={(e) => {
                                      let temp = [...attributes];
                                      temp[index].value = e.target.value;
                                      setAttributes(temp);
                                    }}
                                  />
                                )}

                                <DragIndicatorIcon
                                  style={{
                                    color: "#265075",
                                  }}
                                />

                                {item.notEditable && (
                                  <Delete
                                    style={{
                                      color: "#265075",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      let temp = [...attributes];
                                      temp = temp.filter(
                                        (item, i) => i !== index
                                      );
                                      setAttributes(temp);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr .1fr .1fr",
                gap: "1rem",
                padding: "1.6rem",
                background: "#B8BEC52B ",
              }}
            >
              {error && (
                <p style={{ gridColumn: "span 4", color: "red" }}>{error}</p>
              )}
              <TextField
                placeholder="Attribute e.g Color"
                value={newAttr.name}
                onChange={(e) =>
                  setNewAttr({ ...newAttr, name: e.target.value })
                }
              />
              <TextField
                placeholder="Value e.g Green"
                value={newAttr.value}
                onChange={(e) =>
                  setNewAttr({ ...newAttr, value: e.target.value })
                }
              />
              <span></span>
              <span></span>
              <span></span>

              <div
                style={{
                  display: "flex",
                  justifySelf: "end",
                  justifyContent: "end",
                }}
              >
                <SubmitBtn type="button" onClick={handleAddAttribute}>
                  {" "}
                  Add
                </SubmitBtn>
              </div>
            </div>
          </div>
          <Label>Product Keywords</Label>
          <CustomDiv>
            {keywords.map((item, index) => (
              <div key={index}>
                <div>
                  <div style={keywordDiv}>
                    <p>{item}</p>
                    <ClearIcon
                      sx={{
                        fontSize: ".9rem",
                        cursor: "pointer",
                        border: "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        let temp = keywords.filter((item, i) => i !== index);
                        setKeyWords(temp);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
            <input
              style={keywordInput}
              placeholder="Enter Keywords"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  e.preventDefault();
                  let temp = [...keywords];
                  temp.push(e.target.value);
                  setKeyWords(temp);
                  e.target.value = "";
                }
              }}
            />
          </CustomDiv>
          <h2 style={{ marginTop: "2rem" }}>Trade Information</h2>
          <Label>Unit type*</Label>
          <Select
            required
            value={unitType}
            onChange={(e) => setUnitType(e.target.value)}
          >
            {units.map((unit, index) => (
              <MenuItem key={index} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>

          <Label>Available Quantity*</Label>
          <TextField
            required
            type="number"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
          />

          <Label>Sale Type*</Label>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            required={true}
            onChange={(e) => {
              setTypeofSale(e.target.value);
              setActualPrice("");
              setDiscountPrice("");
              setSalePriceRange(null);
            }}
          >
            <FormControlLabel
              value="sale-by-quantity"
              control={<Radio />}
              label="Sale by quantity"
              checked={typeofSale === "sale-by-quantity" ? true : false}
            />
            <FormControlLabel
              value="sale-by-bulk"
              control={<Radio />}
              label="Sale by bulk"
            />
          </RadioGroup>

          {typeofSale === "sale-by-quantity" && (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <Label>Actual Price</Label>
                <Label>Discounted Price</Label>
                <TextField
                  required
                  value={actualPrice}
                  type="number"
                  onChange={(e) => {
                    let restrictedValue = e.target.value.match(regex)[1];
                    setActualPrice(restrictedValue);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">| $</InputAdornment>
                    ),
                  }}
                />

                <TextField
                  value={discountPrice}
                  type="number"
                  onChange={(e) => {
                    const restrictedValue = e.target.value.match(regex)[1];
                    setDiscountPrice(restrictedValue);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">| $</InputAdornment>
                    ),
                  }}
                />
              </div>
              {+actualPrice < +discountPrice && (
                <p style={errorMsg}>
                  Discounted Price must be less than Actual Price
                </p>
              )}
            </>
          )}
          {typeofSale === "sale-by-bulk" && (
            <>
              <div
                style={{
                  borderRadius: ".4rem",
                  border: "2px solid lightgray",
                }}
              >
                <div style={{ padding: "1.6rem" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr .3fr",
                      gap: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Label>Minimum Quantity*</Label>
                    <Label>Maximum Quantity*</Label>
                    <Label>Actual Price*</Label>
                    <Label>Discounted Price</Label>
                  </div>
                  {salePriceRange &&
                    salePriceRange?.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr .3fr",
                          gap: "1rem",
                          marginBottom: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          value={item.minQty}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | {unitType}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...salePriceRange];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].minQty = customValue;
                            setSalePriceRange(temp);
                          }}
                        />
                        <TextField
                          value={item.maxQty}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | {unitType}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...salePriceRange];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].maxQty = customValue;
                            setSalePriceRange(temp);
                          }}
                        />
                        <TextField
                          value={item.price}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | $
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...salePriceRange];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].price = customValue;
                            setSalePriceRange(temp);
                          }}
                        />
                        <TextField
                          value={item.discountPrice}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | $
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...salePriceRange];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].discountPrice = customValue;
                            console.log(typeof customValue);
                            setSalePriceRange(temp);
                          }}
                        />

                        <Delete
                          style={{
                            color: "#265075",
                            cursor: "pointer",
                            fontSize: "25px",
                          }}
                          onClick={() => {
                            let temp = [...salePriceRange];
                            if (temp.length - 1 !== index && index !== 0) {
                              const newMin = temp[index - 1].maxQty + 1;
                              const nextRow = {
                                ...temp[index + 1],
                                minQty: newMin,
                              };
                              temp.splice(index, 2, nextRow);
                            } else {
                              temp.splice(index, 1);
                            }
                            setSalePriceRange(temp);
                            setNewPriceRange({
                              ...newPriceRange,
                              minQty: temp[temp.length - 1].maxQty + 1,
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr .3fr",
                    gap: "1rem",
                    padding: "1.6rem",
                    background: "#B8BEC52B ",
                  }}
                >
                  {error1 && (
                    <p style={{ gridColumn: "span 5", color: "red" }}>
                      {error1}
                    </p>
                  )}

                  <TextField
                    type="number"
                    required={salePriceRange && !salePriceRange.length}
                    placeholder="e.g 100"
                    value={newPriceRange.minQty}
                    onChange={(e) => {
                      let customValue = e.target.value.match(regex)[1];
                      if (e.target.value !== "")
                        customValue = parseInt(customValue);
                      setNewPriceRange({
                        ...newPriceRange,
                        minQty: customValue,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          | {unitType}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    type="number"
                    required={salePriceRange && !salePriceRange.length}
                    placeholder="e.g 200"
                    value={newPriceRange.maxQty}
                    onChange={(e) => {
                      let customValue = e.target.value.match(regex)[1];
                      if (e.target.value !== "")
                        customValue = parseInt(customValue);
                      setNewPriceRange({
                        ...newPriceRange,
                        maxQty: customValue,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          | {unitType}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type="number"
                    required={salePriceRange && !salePriceRange.length}
                    placeholder="e.g 10"
                    value={newPriceRange.price}
                    onChange={(e) => {
                      let customValue = e.target.value.match(regex)[1];
                      if (e.target.value !== "")
                        customValue = parseInt(customValue);
                      setNewPriceRange({
                        ...newPriceRange,
                        price: customValue,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">| $</InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    type="number"
                    placeholder="e.g 10"
                    value={newPriceRange.discountPrice}
                    onChange={(e) => {
                      let customValue = e.target.value.match(regex)[1];
                      if (e.target.value !== "")
                        customValue = parseInt(customValue);
                      setNewPriceRange({
                        ...newPriceRange,
                        discountPrice: customValue,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">| $</InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifySelf: "end",
                      justifyContent: "end",
                      gridColumn: "span 4",
                    }}
                  >
                    <SubmitBtn type="button" onClick={handleAddPriceRange}>
                      {" "}
                      Add
                    </SubmitBtn>
                  </div>
                </div>
              </div>
              {+newPriceRange.price < +newPriceRange.discountPrice && (
                <p style={errorMsg}>
                  Discounted Price must be less than Actual Price
                </p>
              )}
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <div>
              <Label>Tax exempt</Label>
              <p
                style={{
                  fontSize: ".8rem",
                  color: "gray",
                }}
              >
                Determines whether this product is tax exempt (tax will not be
                applied to this product at checkout).
              </p>
            </div>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setTax(e.target.value)}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </div>

          <Label>Product Certificates</Label>

          <div style={{ display: "flex", gap: "1rem" }}>
            {prodCertificates.length < 6 && (
              <>
                <label htmlFor="image1" className="img-upload">
                  <CloudUploadIcon />
                </label>
                <input
                  type="file"
                  hidden
                  id="image1"
                  accept="image/*,.pdf"
                  onChange={(e) =>
                    handleFilesUpload(e, prodCertificates, setProdCertificates)
                  }
                />
              </>
            )}
            {prodCertificates.map((item, index) => (
              <UploadDiv key={item?.id}>
                <>
                  {item?.type === "image" ? (
                    <img
                      src={item?.url}
                      height="90px"
                      width="100px"
                      style={{ objectFit: "contain" }}
                    />
                  ) : (
                    <a href={item?.url} target="blank">
                      <img
                        src="/images/pdf.png"
                        height="90px"
                        width="100px"
                        style={{ objectFit: "contain" }}
                      />
                    </a>
                  )}
                </>

                <ClearIcon
                  style={clrIcon}
                  onClick={() => {
                    let temp = prodCertificates.filter(
                      (item, i) => i !== index
                    );
                    setProdCertificates(temp);
                  }}
                />
              </UploadDiv>
            ))}
          </div>

          <h2 style={{ marginTop: "1rem" }}>Logistics Information</h2>
          <Label>Expected Dispatch time (Hrs)*</Label>
          <TextField
            required
            value={EDT}
            onChange={(e) => setEDT(e.target.value.match(regex)[1])}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">| Hrs</InputAdornment>
              ),
            }}
            type="number"
          />
          {dates?.mfgDate > dates?.expDate && (
            <p style={errorMsg}>
              Expiry Date must be greater than Manufacturing Date
            </p>
          )}

          {prodError && <p style={{ color: "red" }}>{prodError}</p>}
          <SubmitBtn className={disable && "btn-disabled"} type="submit">
            Add
          </SubmitBtn>
        </StyledCard>
      </form>
      <ErrorBar
        text={uploadError}
        email={""}
        state={openErr}
        setState={setOpenErr}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

<StyledDropzone />;
