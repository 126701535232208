import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Card,
  Divider,
  Menu,
  MenuItem,
  TableHead,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { deleteSellerUser, updateSellerUser } from "../graphql/mutations";
import { getSellerUser } from "../graphql/queries";
import {
  CTableRow,
  Label,
  STableHead,
  StyledTableRow,
  SubmitBtn,
  modalStyle,
} from "./styles";

export default function UserTable({ users, setUpdateData }) {
  const currentUser = useSelector((state) => state.userInfo?.payload);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currItem, setCurrItem] = useState(null);
  const [sellerUser, setSellerUser] = useState([]);
  const [currUserId, setCurrUserId] = useState("");
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // QUERIES
  const getSellerUser = /* GraphQL */ `
    query GetSellerUser($id: ID!) {
      getSellerUser(id: $id) {
        id
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        user {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        role
        createdAt
        updatedAt
      }
    }
  `;
  // QUERIES END

  // MUTATIONS
  const deleteSellerUser = /* GraphQL */ `
    mutation DeleteSellerUser(
      $input: DeleteSellerUserInput!
      $condition: ModelSellerUserConditionInput
    ) {
      deleteSellerUser(input: $input, condition: $condition) {
        id
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        user {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        role
        createdAt
        updatedAt
      }
    }
  `;
  const updateSellerUser = /* GraphQL */ `
    mutation UpdateSellerUser(
      $input: UpdateSellerUserInput!
      $condition: ModelSellerUserConditionInput
    ) {
      updateSellerUser(input: $input, condition: $condition) {
        id
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        user {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        role
        createdAt
        updatedAt
      }
    }
  `;
  // MUTATIONS END
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrItem(null);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleUpdateUser(e) {
    e.preventDefault();
    API.graphql({
      query: updateSellerUser,
      variables: {
        input: {
          id: currUserId,
          role: sellerUser?.role,
        },
      },
    }).then((res) => {
      setUpdateData(new Date().getMilliseconds());
      setOpenModal(false);
      console.log(res.data.updateSellerUser, " updateComplete");
    });
  }

  function deleteUser() {
    API.graphql({
      query: deleteSellerUser,
      variables: {
        input: {
          id: currUserId,
        },
      },
    })
      .then((res) => {
        console.log(res, "deleteUser");
        setOpen(false);
        setUpdateData(new Date().getMilliseconds());
      })
      .catch((e) => console.log(e, "deleteUser failed"));
  }

  function fetchUser() {
    API.graphql({
      query: getSellerUser,
      variables: {
        id: currItem,
      },
    }).then((res) => setSellerUser(res.data.getSellerUser));
  }

  if (users.length === 0)
    return (
      <div className="no-items">
        <img src="/images/noProds.svg" alt="" width="300px" />
        <h2>No Seller Users</h2>
      </div>
    );

  return (
    <Card>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <CTableRow>
            <STableHead>Name</STableHead>
            <STableHead>Email</STableHead>
            <STableHead>Role</STableHead>
            <STableHead>Reg. Date</STableHead>
            {currentUser?.role === "OWNER" && (
              <STableHead align="right">Action</STableHead>
            )}
          </CTableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : users
          )?.map((row, i) => (
            <StyledTableRow key={i}>
              <TableCell style={{ width: 160, fontSize: ".9rem" }} align="left">
                {row?.user?.name}
              </TableCell>
              <TableCell style={{ width: 160 }}>{row?.user?.email}</TableCell>

              <TableCell style={{ width: 160 }}>{row?.role}</TableCell>
              <TableCell style={{ width: 160 }}>
                {row.createdAt.slice(0, 10)}
              </TableCell>

              {currentUser?.role === "OWNER" && (
                <TableCell align="right" style={{ width: 160 }}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={
                      currItem === row.id ? "long-menu" : undefined
                    }
                    aria-expanded={currItem === row.id ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(e) => {
                      setCurrItem(row.id);
                      handleClick(e);
                      setCurrUserId(row.id);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={currItem === row.id ? true : false}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "10rem",
                        boxShadow: "1px 1px 2px lightgray",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpenModal(true);
                        setOpen(false);
                        handleCloseMenu();
                        fetchUser(currItem);
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        setOpen(true);
                        handleCloseMenu();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              )}
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">
          {" Do you really want to delete the User ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Once Delete the User will be deleted permanently!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button onClick={deleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={(e) => handleUpdateUser(e)}>
          <Box sx={modalStyle}>
            {/* <h2 style={{ marginBottom: ".7rem" }}>Invite User</h2> */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <h2 style={{ marginBottom: ".7rem" }}>Invite User </h2>
              <CloseIcon
                onClick={() => setOpenModal(false)}
                sx={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ marginBottom: ".7rem" }} />
            <Label>Name*</Label>
            <TextField
              disabled
              size="small"
              placeholder="e.g John Doe"
              required
              value={sellerUser?.user?.name || ""}
            />
            <Label>Email*</Label>
            <TextField
              disabled
              size="small"
              placeholder="e.g john@wtx.com"
              required
              type="email"
              value={sellerUser?.user?.email || ""}
            />
            <Label>Role</Label>
            <TextField
              select
              size="small"
              label="Choose a role"
              value={sellerUser?.role || ""}
              onChange={(e) => {
                let temp = { ...sellerUser };
                temp.role = e.target.value;
                setSellerUser(temp);
              }}
            >
              <MenuItem value={"OWNER"}>OWNER</MenuItem>
              <MenuItem value={"EDITOR"}>EDITOR</MenuItem>
              <MenuItem value={"VIEWER"}>VIEWER</MenuItem>
            </TextField>
            <div style={{ marginTop: "1rem" }}>
              <SubmitBtn style={{ float: "right" }} type="submit">
                Save
              </SubmitBtn>
            </div>
          </Box>
        </form>
      </Modal>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={(e) => onPageChange(e, 0)}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page - 1)}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
