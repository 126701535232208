import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Card, IconButton, InputAdornment, TextField } from "@mui/material";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import CustomizedSnackbars from "../../components/SnackBar";
import {
  Label,
  SubmitBtn,
  authCard,
  errorMsg,
  formStyle,
  otpStyle,
  textStyle,
} from "../../components/styles";

const Login = () => {
  document.title = "Login | World Trade X";
  const formRef = useRef(null);
  const [error, setError] = useState(null);
  const [confirmed, setConfirmed] = useState(true);
  const [formValue, setFormValue] = useState(null);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(-1);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const [showPassword, setShowPassword] = useState(false);
  // QUERIES
  const listSellerUsers = /* GraphQL */ `
    query ListSellerUsers(
      $filter: ModelSellerUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSellerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          sellerId
          seller {
            id
            name
            profile
            image
            taxId
            active
            verified
            phone
            email
            website
            address
            attributes
            images
            documents
            rating
            bank
            products {
              items {
                name
                active
                createdAt
                description
                id
                image
                images
                listPrice
                verified
                unitType
                unitPrice
                tierPrice
                productCategory {
                  name
                  productSubCategories {
                    items {
                      name
                    }
                  }
                }
              }
              nextToken
            }
            orders {
              items {
                id
              }
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          userId
          role
          createdAt
          user {
            name
            email
            id
            phone
            role
          }
          updatedAt
        }
        nextToken
      }
    }
  `; //QUERIES END
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleFormSubmit = async ({ email, password }) => {
    setError(null);
    await Auth.signIn(email, password)
      .then((res) => {
        API.graphql(
          graphqlOperation(listSellerUsers, {
            filter: { userId: { eq: res.username } },
          })
        ).then((userData) => {
          if (userData?.data?.listSellerUsers?.items?.length === 0) {
            setError("Incorrect username or password");
            localStorage.clear();
          } else {
            navigate("/");
          }
        });
      })
      .catch((e) => {
        if (e.message === "User is not confirmed.") {
          setConfirmed(false);
          ResendConfirmationCode(email);
        }
        setError(e.message);
      });
  };

  const ResendConfirmationCode = async (email) => {
    await Auth.resendSignUp(email)
      .then((res) => {
        let temp = { ...state };
        temp.open = true;
        setState(temp);
        setTimer(30);
      })
      .catch((e) => console.log(e));
  };

  const handleConfirmUser = async ({ email, code }) => {
    setError(null);
    code = code.toString();
    await Auth.confirmSignUp(email, code)
      .then((res) => {
        console.log(res);
        setConfirmed(true);
        navigate("/reset-password");
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      });
  };

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  return (
    <div className="auth-container">
      <Card className="session-card" sx={authCard}>
        <img
          src="/images/logo.png"
          alt="logo"
          height="70px"
          width="70px"
          style={{ alignSelf: "center" }}
        />
        <div>
          <h3 style={{ textAlign: "center", marginBottom: ".3rem" }}>
            Welcome to World Trade X
          </h3>
          <p style={textStyle}>
            Don't have account?{" "}
            <Link to="/signup">
              <span
                style={{
                  borderBottom: "1px solid black",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Sign Up
              </span>
            </Link>
          </p>
        </div>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setFormValue(values);
            handleFormSubmit(values);
            setErrors(false);
            setTimeout(() => {
              setSubmitting(false);
            }, 300);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form style={formStyle} onChange={() => setError(null)}>
              <Label>Email*</Label>
              <Field
                as={TextField}
                type="email"
                name="email"
                size="small"
                autoComplete="off"
                placeholder="john@yahoo.com"
              />
              <ErrorMessage style={errorMsg} name="email" component="span" />

              {confirmed && (
                <>
                  <Label>Password*</Label>
                  <Field
                    as={TextField}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    size="small"
                    autoComplete="off"
                    placeholder="********"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage
                    style={errorMsg}
                    name="password"
                    component="span"
                  />
                </>
              )}

              {!confirmed && (
                <>
                  <Label>Verification Code*</Label>
                  <Field
                    as={NumericFormat}
                    type="text"
                    name="code"
                    size="small"
                    autoComplete="off"
                    placeholder="Enter the 6 Digit PIN sent to your Email"
                    maxLength={6}
                    style={otpStyle}
                  />
                  <ErrorMessage style={errorMsg} name="code" component="span" />
                </>
              )}

              {error && <p style={errorMsg}>{error}</p>}
              {confirmed && (
                <SubmitBtn
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </SubmitBtn>
              )}
              {!confirmed && (
                <SubmitBtn
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => handleConfirmUser(values)}
                >
                  Submit
                </SubmitBtn>
              )}
              {!confirmed && (
                <div style={textStyle}>
                  {timer > 0 && (
                    <span>
                      Resend code in <b> {timer}s</b>
                    </span>
                  )}

                  {timer === 0 && (
                    <div>
                      Didn't recieve the code ?{" "}
                      <button
                        type="button"
                        className="resendBtn"
                        onClick={() =>
                          ResendConfirmationCode(formRef.current.values.email)
                        }
                      >
                        Resend
                      </button>
                    </div>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
        {confirmed && (
          <p
            style={{
              textAlign: "center",
              background: "#d0d0e1",
              padding: "1.5rem",
              margin: "0",
              borderRadius: ".4rem",
            }}
          >
            Forgot your password?{" "}
            <Link to="/reset-password">
              <span
                style={{
                  borderBottom: "1px solid black",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Reset It
              </span>
            </Link>
          </p>
        )}
      </Card>
      <CustomizedSnackbars
        text={"Code has been sent to"}
        email={formValue?.email}
        state={state}
        setState={setState}
      />
    </div>
  );
};

let initialValues = {
  email: "",
  password: "",
  code: "",
};
const formSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
});

export default Login;
