import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Rating,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Label,
  modalStyle,
  NumberFormat,
  ODiv,
  OrderCard,
  SubmitBtn,
  textFieldStyle,
} from "../../src/components/styles";
import SnackBar from "../components/SnackBar";
import OrderStepper from "../components/Stepper";

export default function OrderDetails() {
  const { orderID } = useParams();
  const [operation, setOperation] = useState("");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState({
    type: "",
    msg: "",
  });

  const [bar, setBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const user = useSelector((state) => state.userInfo?.payload);
  const [shippingDetails, setShippingDetails] = useState({
    courierName: "",
    otherCourier: "",
    trackingNumber: "",
    link: "",
    instruction: "",
  });

  const [billingAddress, setBillingAddress] = useState([]);
  // QUERIES
  const getOrder = /* GraphQL */ `
    query GetOrder($id: ID!) {
      getOrder(id: $id) {
        id
        code
        items
        rating
        buyerId
        buyer {
          id
          buyerType
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          categories
          rating
          createdAt
          updatedAt
          buyerCartId
        }
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        taxTotal
        deliveryTotal
        subTotal
        total
        items
        pickupAddress
        billingAddress
        deliveryAddress
        orderStatus
        paymentStatus
        changeLog
        shipmentDetails
        orderDate
        createdAt
        updatedAt
      }
    }
  `;
  // QUERIES END

  // MUTATIONS
  const updateOrder = /* GraphQL */ `
    mutation UpdateOrder(
      $input: UpdateOrderInput!
      $condition: ModelOrderConditionInput
    ) {
      updateOrder(input: $input, condition: $condition) {
        id
        code
        items
        rating
        buyerId
        buyer {
          id
          buyerType
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          categories
          rating
          createdAt
          updatedAt
          buyerCartId
        }
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        taxTotal
        deliveryTotal
        subTotal
        total
        items
        pickupAddress
        billingAddress
        deliveryAddress
        orderStatus
        paymentStatus
        changeLog
        shipmentDetails
        orderDate
        createdAt
        updatedAt
      }
    }
  `;
  //MUTATIONS END
  function handleShipping() {
    API.graphql({
      query: updateOrder,
      variables: {
        input: {
          id: orderID,
          shipmentDetails: JSON.stringify(shippingDetails),
          orderStatus: "IN_TRANSIT",
        },
      },
    }).then((res) => {
      setOrder(res?.data?.updateOrder);
      setOpenModal(false);
      setOrderStatus({
        ...orderStatus,
        type: "success",
        msg: "Order has been shipped",
      });
      setBar({ ...bar, open: true });
    });
  }

  function Prompt() {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Do you really want to ${operation}?`}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <Button
            onClick={() => {
              mutateOrder(status);
              setOpen(false);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleInputChange(value, field) {
    let temp = { ...shippingDetails };
    temp[field] = value;
    setShippingDetails(temp);
  }

  function mutateOrder(status) {
    API.graphql({
      query: updateOrder,
      variables: {
        input: {
          id: order?.id,
          orderStatus: status,
        },
      },
    })

      .then((res) => {
        setOrder(res?.data?.updateOrder);
        let type = "success";
        let msg = "";

        if (status === "READY_FOR_PICKUP") {
          type = "success";
          msg = "Order has been accepted.";
        } else if (status === "CANCELLED") {
          type = "error";
          msg = "Order has been rejected.";
        } else {
          type = "success";
          msg = "Order has been delivered.";
        }

        // let type = status === ("READY_FOR_PICKUP" || "DELIVERED") && "success";
        // let msg = status === "READY_FOR_PICKUP" && "Order has been accepted.";

        // type = status === "CANCELLED" && "error";
        // msg = status === "CANCELLED" && "Order has been rejected.";
        // msg = status === "DELIVERED" && "Order has been delivered.";

        setOrderStatus({
          ...orderStatus,
          type: type,
          msg: msg,
        });
        setBar({ ...bar, open: true });
      })
      .catch((e) => console.log(e));
  }

  function numberToWords(num) {
    const ones = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const scales = ["", "thousand", "million", "billion", "trillion"];

    if (num === 0) return "zero";

    let words = "";
    for (let i = scales.length - 1; i >= 0; i--) {
      const scale = 10 ** (3 * i);
      if (num >= scale) {
        const count = Math.floor(num / scale);
        num %= scale;
        const hundreds = Math.floor(count / 100);
        const tensCount = count % 100;
        if (hundreds > 0) {
          words += ones[hundreds] + " hundred ";
        }
        if (tensCount > 0) {
          if (tensCount < 20) {
            words += ones[tensCount] + " ";
          } else {
            const tensDigit = Math.floor(tensCount / 10);
            const onesDigit = tensCount % 10;
            words += tens[tensDigit] + " " + ones[onesDigit] + " ";
          }
        }
        words += scales[i] + " ";
      }
    }
    return words.charAt(0).toUpperCase() + words.slice(1);
  }

  //get order details
  useEffect(() => {
    API.graphql({
      query: getOrder,
      variables: {
        id: orderID,
      },
    })
      .then((res) => {
        setLoading(false);
        setOrder(res?.data?.getOrder);
        setBillingAddress(JSON.parse(res?.data?.getOrder?.billingAddress));
      })
      .catch((e) => console.log(e, "eerrr"));
  }, [shippingDetails, orderID]);

  if (loading) return <CircularProgress className="loader" />;

  return (
    <Box
      className="base-container"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        mb: 2,
        mt: 2,
      }}
    >
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <Link to="/orders">
            <ArrowBackIosIcon sx={{ fontSize: "medium", marginTop: ".3rem" }} />
          </Link>
          <h2>Order Info </h2>
        </div>
        {order?.orderStatus === "OPEN" && user?.role !== "VIEWER" && (
          <>
            <SubmitBtn
              style={{ marginLeft: "auto" }}
              onClick={() => {
                setOpen(true);
                setStatus("READY_FOR_PICKUP");
                setOperation("accept this order");
              }}
            >
              {" "}
              Accept Order
            </SubmitBtn>
            <SubmitBtn
              style={{ background: "red" }}
              onClick={() => {
                setOpen(true);
                setStatus("CANCELLED");
                setOperation("reject this order");
              }}
            >
              Reject Order
            </SubmitBtn>
          </>
        )}

        {order?.orderStatus === "READY_FOR_PICKUP" &&
          user?.role !== "VIEWER" && (
            <SubmitBtn
              style={{ marginLeft: "auto" }}
              onClick={() => setOpenModal(true)}
            >
              Mark as Shipped
            </SubmitBtn>
          )}

        {order?.orderStatus === "CANCELLED" && (
          <SubmitBtn
            style={{
              marginLeft: "auto",
              background: "red",
              cursor: "revert",
            }}
          >
            Order has beed Canceled
          </SubmitBtn>
        )}
        {order?.orderStatus === "RETURNED" && (
          <SubmitBtn
            style={{
              marginLeft: "auto",
              background: "red",
              cursor: "revert",
            }}
          >
            Order has beed Returned
          </SubmitBtn>
        )}

        {order?.orderStatus === "IN_TRANSIT" && user?.role !== "VIEWER" && (
          <SubmitBtn
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setStatus("DELIVERED");
              setOperation("mark this order as delivered");
              setOpen(true);
            }}
          >
            {" "}
            Mark as Delivered
          </SubmitBtn>
        )}

        {order?.orderStatus === "DELIVERED" && (
          <SubmitBtn
            style={{
              marginLeft: "auto",
              background: "green",
              cursor: "revert",
            }}
          >
            Order has beed Delivered
          </SubmitBtn>
        )}
      </div>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <OrderCard>
            <h3 style={{ marginBottom: "1rem" }}>Buyer Details</h3>
            <h4>{order?.buyer?.name}</h4>
            <p>{billingAddress?.address_line1}</p>
            <p>{billingAddress?.address_line2}</p>
            <p>
              {billingAddress?.city}, {billingAddress?.country},{" "}
              {billingAddress?.postal_code}
            </p>
            <p>Email : {order?.buyer?.email}</p>
            <p>Phone : {order?.buyer?.phone}</p>
          </OrderCard>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
      <h3>Product Details</h3>

      {order &&
        JSON.parse(order?.items)?.map((item, index) => (
          <OrderCard key={index}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 6 }}
              sx={{
                display: "flex",
                alignItems: "center",
                lineHeight: "2rem",
              }}
            >
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={item?.thumbnail}
                    alt="order"
                    height="70px"
                    width="70px"
                    style={{ objectFit: "cover" }}
                  />
                  <div>
                    <h3>{item?.name}</h3>
                    <p>
                      <b>Price - $</b>
                      <NumberFormat
                        value={(item?.price).toFixed(2)}
                        allowLeadingZeros
                        thousandSeparator=","
                      />
                    </p>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <p>
                    <b>Total Price - </b> $
                    <NumberFormat
                      value={(item?.price * item?.quantity).toFixed(2)}
                      allowLeadingZeros
                      thousandSeparator=","
                    />
                  </p>
                  <p>
                    <b>Total Qty - </b>
                    {item?.quantity}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div>
                  <p>
                    <b>SKU</b> - {item?.sku || "Not Available"}
                  </p>
                  <p>
                    <b>Placed Date</b> -{" "}
                    {new Date(order?.createdAt).toLocaleDateString()}
                  </p>
                  {/* <p>
                    {" "}
                    <b>Confirmed Date</b> -{" "}
                    {new Date(order?.updatedAt).toLocaleDateString()}
                  </p> */}
                </div>
              </Grid>
            </Grid>
          </OrderCard>
        ))}

      <OrderCard>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ lineHeight: "3rem" }}
        >
          <Grid item xs={12} md={6}>
            <div>
              <h3>Payment Details</h3>
              <p>
                <b>Payment Method</b> : Cash
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <h3>Billing Details</h3>
              <ODiv>
                <b>Gross Amount($)</b>
                <NumberFormat
                  value={order?.subTotal.toFixed(2)}
                  allowLeadingZeros
                  thousandSeparator=","
                  style={{ border: "none", pointerEvents: "none" }}
                />
              </ODiv>
              <ODiv>
                <b>Logistic Charges($)</b>
                <p>0</p>
              </ODiv>
              <Divider />
              <ODiv>
                <b>Total Amount($)</b>
                <NumberFormat
                  value={order?.total.toFixed(2)}
                  allowLeadingZeros
                  thousandSeparator=","
                  style={{ border: "none", pointerEvents: "none" }}
                />
                {/* <p>{order?.total.toFixed(2)}</p> */}
              </ODiv>
              <ODiv style={{ lineHeight: "1.2rem" }}>
                <b>Total Amount(In Words)</b>
                <b>{numberToWords(order?.total)} only</b>
              </ODiv>
            </div>
          </Grid>
        </Grid>
      </OrderCard>
      <OrderCard>
        <h3 style={{ marginBottom: "2rem" }}>Order Status</h3>
        <OrderStepper orderStatus={order?.orderStatus} />
        {/* <Link to="/orders/status/1">
          <MoreBtn>
            See All Updates <ChevronRightIcon />{" "}
          </MoreBtn>
        </Link> */}
      </OrderCard>
      <OrderCard sx={{minHeight:"5rem !important"}}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Order Rating:</h3>
          {order?.rating !== null ? (
            <Rating
              name="half-rating"
              defaultValue={order?.rating}
              precision={0.1}
              readOnly
            />
          ) : (
            <i>Not rated yet.</i>
          )}
        </div>
      </OrderCard>
      <SnackBar
        text={orderStatus.msg}
        email={""}
        state={bar}
        setState={setBar}
        type={orderStatus.type}
      />
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleShipping();
          }}
        >
          <Box sx={modalStyle}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <h2 style={{ marginBottom: ".7rem" }}>Add Shipping Details </h2>
              <CloseIcon
                onClick={() => setOpenModal(false)}
                sx={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ marginBottom: ".7rem" }} />
            <Label>Choose Courier*</Label>
            <TextField
              required
              size="small"
              select
              value={shippingDetails.courierName}
              onChange={(e) => {
                handleInputChange(e.target.value, "courierName");
              }}
            >
              <MenuItem value="FedEx">FedEx</MenuItem>
              <MenuItem value="USPS">USPS</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            {shippingDetails.courierName === "Other" && (
              <>
                <Label>Courier Name</Label>
                <TextField
                  required
                  size="small"
                  value={shippingDetails.otherCourier}
                  onChange={(e) => {
                    handleInputChange(e.target.value, "otherCourier");
                  }}
                />
              </>
            )}
            <Label>Tracking Number*</Label>
            <TextField
              required={shippingDetails.courierName !== "Other" ? true : false}
              size="small"
              value={shippingDetails.trackingNumber}
              onChange={(e) => {
                handleInputChange(e.target.value, "trackingNumber");
              }}
            />

            {shippingDetails.courierName === "Other" && (
              <>
                <Label>Tracking Link</Label>
                <TextField
                  required
                  size="small"
                  value={shippingDetails.link}
                  onChange={(e) => {
                    handleInputChange(e.target.value, "link");
                  }}
                />
              </>
            )}
            <Label>Delivery Instruction</Label>
            <TextareaAutosize
              value={shippingDetails.instruction}
              onChange={(e) => {
                handleInputChange(e.target.value, "instruction");
              }}
              minRows={6}
              maxLength={500}
              style={textFieldStyle}
            />
            <SubmitBtn>Save</SubmitBtn>
          </Box>
        </form>
      </Modal>
      <Prompt />
    </Box>
  );
}
