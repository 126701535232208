import { useState } from "react";

const Image = (link) => {
  const [imgUrl, setImgUrl] = useState(link.link);
  return (
    <img
      src={imgUrl}
      alt="img"
      onError={() => setImgUrl("/images/dummy.png")}
      width="50px"
      height="50px"
      style={{ objectFit: "cover", marginLeft: ".5rem", borderRadius: ".4rem" }}
    />
  );
};

export default Image;
