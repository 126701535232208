import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="div-center">
        <img src="/images/logo.png" width={100} />
      </div>
      <div className="div-center">
        <h2 className="mt2 mb1">Privacy Policy for WorldTradeX Seller App</h2>
      </div>
      <div>
        <p className="normal-para ">Effective Date: 6th of June, 2023</p>
        <p className="normal-para mt1">
          At WorldTradeX, we value and respect your privacy. This Privacy Policy
          outlines how we collect, use, and protect your personal information
          when you use our WorldTradeX Seller App ("App") to sell items. By
          using our App, you consent to the practices described in this Privacy
          Policy. Please take the time to review this policy carefully.
        </p>
        <h3 className="mt1">1. Information We Collect</h3>
        <p className="normal-para mt1">
          1.1 Account Information: When you create an account on the WorldTradeX
          Seller App, we may collect your name, email address, contact details,
          and any other information you provide during the registration process.
        </p>
        <p className="normal-para mtSm">
          1.2 Transaction Information: To facilitate transactions between
          sellers and buyers, we collect information about the items you list
          for sale, pricing, shipping details, and any communication or
          interaction that occurs within the App.
        </p>
        <p className="normal-para mtSm ">
          1.3 Financial Information: We may collect payment-related information,
          such as your bank account details or payment processor details, to
          process payments for your sales on the App. Please note that we do not
          store credit card information on our servers. All payment transactions
          are securely processed through our trusted third-party payment
          processors.
        </p>
        <p className="normal-para mtSm ">
          1.4 Usage Information: We may collect information about your usage of
          the WorldTradeX Seller App, including your device information, IP
          address, app version, and activity logs. This information helps us
          improve and optimize our services.
        </p>
        <h3 className="mt1"> 2. Use of Information</h3>
        <p className="normal-para mtSm ">
          2.1 Providing Services: We use the collected information to provide
          you with access to and usage of the WorldTradeX Seller App. This
          includes facilitating transactions, managing your account, and
          communicating with you regarding your sales and account activity.
        </p>{" "}
        <p className="normal-para mtSm ">
          2.2 Improving Services: We analyze usage data to improve and enhance
          our App's functionality, user experience, and overall performance.
        </p>{" "}
        <p className="normal-para mtSm ">
          2.3 Communication: We may use your contact information to send you
          important notifications, updates, or promotional offers related to the
          App. You can opt out of receiving marketing communications at any
          time.
        </p>{" "}
        <p className="normal-para mtSm ">
          2.4 Compliance and Legal Obligations: We may use your information to
          comply with applicable laws, regulations, or legal processes, respond
          to governmental requests, or protect our rights, privacy, safety, or
          property.
        </p>
        <h3 className="mt1">3. Information Sharing</h3>
        <p className="normal-para mtSm ">
          3.1 Third-Party Service Providers: We may share your information with
          trusted third-party service providers who assist us in providing and
          improving our services. These providers are obligated to maintain the
          confidentiality and security of your information.
        </p>{" "}
        <p className="normal-para mtSm ">
          3.2 Business Transfers: In the event of a merger, acquisition, or sale
          of all or a portion of our assets, your information may be transferred
          to the acquiring entity. We will notify you before your information
          becomes subject to a different privacy policy.
        </p>{" "}
        <p className="normal-para mtSm ">
          3.3 Legal Requirements: We may disclose your information if required
          by law, regulation, legal process, or governmental request.
        </p>
      </div>
      <h3 className="mt1">4. Data Security</h3>
      <p className="normal-para mtSm ">
        We take reasonable measures to protect the security and integrity of
        your personal information. However, no method of data transmission or
        storage is entirely secure. Therefore, we cannot guarantee absolute
        security, and you use our App at your own risk.{" "}
      </p>
      <h3 className="mt1">5. Your Choices</h3>
      <p className="normal-para mtSm ">
        5.1 Account Settings: You can review and update your account information
        by accessing the settings within the WorldTradeX Seller App.
      </p>{" "}
      <p className="normal-para mtSm ">
        5.2 Marketing Communications: You can opt out of receiving marketing
        communications by following the unsubscribe instructions provided in the
        emails or by contacting us directly.
      </p>{" "}
      <p className="normal-para mtSm ">
        5.3 Data Retention: We retain your personal information for as long as
        necessary to fulfill the purposes outlined in this Privacy Policy,
        unless a longer retention period is required or permitted by law.
      </p>
      <h3 className="mt1">6. Children's Privacy</h3>
      <p className="normal-para mtSm ">
        The WorldTradeX Seller App is not intended for use by individuals under
        the age of 18. We do not knowingly collect personal information from
        children. If you believe we have unintentionally collected personal
        information from a child, please contact us to request its deletion.
      </p>
      <h3 className="mt1">7. Changes to this Privacy Policy</h3>
      <p className="normal-para mtSm ">
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and the "Effective Date" at the top will indicate
        the latest revision date. It is your responsibility to review this
        Privacy Policy periodically for any updates.
      </p>
      <h3 className="mt1">8. Contact Us</h3>
      <p className="normal-para mtSm ">
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or the use of your personal information, please contact us at
        support@worldtradex.com.
      </p>{" "}
      <b className="normal-para mt1 ">
        Thank you for using the WorldTradeX Seller App.
      </b>
    </div>
  );
};

export default PrivacyPolicy;
