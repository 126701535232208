import { Delete, InfoRounded } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { API, Auth, Storage, graphqlOperation } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ErrorBar from "../components/ErrorBar";
import SnackBar from "../components/SnackBar";
import {
  Label,
  SubmitBtn,
  clrIcon,
  errorMsg,
  numStyle,
  textFieldStyle,
} from "../components/styles";
import { updateSeller } from "../graphql/mutations";
import { getSeller, listSellerUsers } from "../graphql/queries";
import { setSellerInfo } from "../store/getSellerSlice";

export default function Verify() {
  document.title = "KYC | World Trade X";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [seller, setSeller] = useState([]);
  const [images, setImages] = useState([]);
  const [awards, setAwards] = useState([]);
  const [error, setError] = useState("");

  const [certificates, setCertificates] = useState([
    { id: "", name: "", type: "", url: "" },
  ]);

  const [loading, setLoading] = useState(true);

  const [bar, setBar] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [openErr, setOpenErr] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });

  const initialValues = {
    name: seller?.name || "",
    email: seller?.email || "",
    phone: "",
    image: "",
    address_line1: "",
    address_line2: "",
    state: "",
    city: "",
    postal_code: "",
    country: "",
    latitude: "",
    logitude: "",
    about: "",
    certificate: "",
    bankName: "",
    accountName: "",
    accountNumber: "",
    routingNumber: "",
  };
  //QUERIES
  const getSeller = /* GraphQL */ `
    query GetSeller($id: ID!) {
      getSeller(id: $id) {
        id
        name
        profile
        image
        taxId
        active
        verified
        phone
        email
        website
        address
        attributes
        images
        documents
        rating
        bank
        products {
          items {
            name
            active
            createdAt
            description
            id
            image
            images
            listPrice
            verified
            unitType
            unitPrice
            tierPrice
            productCategory {
              name
              productSubCategories {
                items {
                  name
                }
              }
            }
          }
          nextToken
        }
        orders {
          items {
            id
            items
            orderStatus
          }
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  `;
  const listSellerUsers = /* GraphQL */ `
    query ListSellerUsers(
      $filter: ModelSellerUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSellerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          sellerId
          seller {
            id
            name
            profile
            image
            taxId
            active
            verified
            phone
            email
            website
            address
            attributes
            images
            documents
            rating
            bank
            products {
              items {
                name
                active
                createdAt
                description
                id
                image
                images
                listPrice
                verified
                unitType
                unitPrice
                tierPrice
                productCategory {
                  name
                  productSubCategories {
                    items {
                      name
                    }
                  }
                }
              }
              nextToken
            }
            orders {
              items {
                id
              }
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          userId
          role
          createdAt
          user {
            name
            email
            id
            phone
            role
          }
          updatedAt
        }
        nextToken
      }
    }
  `;
  //QUERIES END

  // MUTATIONS
  const updateSeller = /* GraphQL */ `
    mutation UpdateSeller(
      $input: UpdateSellerInput!
      $condition: ModelSellerConditionInput
    ) {
      updateSeller(input: $input, condition: $condition) {
        id
        name
        profile
        image
        taxId
        active
        verified
        phone
        email
        website
        address
        attributes
        images
        documents
        rating
        bank
        products {
          items {
            active
            createdAt
            description
            id
            image
            images
            listPrice
            verified
            unitType
            unitPrice
            tierPrice
            productCategory {
              name
              productSubCategories {
                items {
                  name
                }
              }
            }
          }
          nextToken
        }
        orders {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  `;
  // MUTATIONS END
  function handleAddMoreCert() {
    let temp = [...certificates];
    temp.push({ id: "", name: "", type: "", url: "" });
    setCertificates(temp);
  }

  async function updateSellerApi({
    name,
    email,
    phone,
    address_line1,
    address_line2,
    state,
    city,
    country,
    postal_code,
    latitude,
    longitude,
    about,
    bankName,
    accountName,
    accountNumber,
    routingNumber,
  }) {
    setError("");
    await API.graphql({
      query: updateSeller,
      variables: {
        input: {
          id: seller?.id,
          name,
          email,
          phone,
          image: seller?.image,
          profile: about,
          images: JSON.stringify({
            certificates: awards,
            gallery: images,
          }),
          documents: JSON.stringify(certificates),
          address: JSON.stringify({
            address_line1,
            address_line2,
            state,
            city,
            postal_code,
            country,
            latitude,
            longitude,
          }),
          bank: JSON.stringify({
            bank_name: bankName,
            account_name: accountName,
            account_number: accountNumber,
            routing_number: routingNumber,
          }),
        },
      },
    })
      .then((res) => {
        setSeller(res.data?.updateSeller);
        dispatch(setSellerInfo(res.data?.updateSeller));
        setBar({ ...bar, open: true });
      })
      .catch((e) => {
        if (e.errors[0].message.includes("website")) {
          setError("Invalid website !");
        } else if (e.errors[0].message.includes("phone")) {
          setError("Invalid Phone ! ");
        } else {
          setError(e.errors[0].message);
        }
        setOpenErr({ ...openErr, open: true });
      });
  }

  async function handleProfileImageUpload(e) {
    const file = e.target.files[0];
    let type = file?.type.substring(0, file?.type.indexOf("/"));
    if (type !== "image") {
      setError("Only Images are allowed");
      setOpenErr({ ...openErr, open: true });
      return;
    }
    setOpen(true);
    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
      }
    )
      .then((result) => {
        Storage.get(result.key)
          .then((res) => {
            let temp = { ...seller };
            temp.image = res.substring(0, res.indexOf("?"));
            setSeller(temp);
            setOpen(false);
          })
          .catch((e) => setOpen(false));
      })
      .catch((err) => {
        setOpen(false);
        setError("Upload Failed, Try Different Image");
        setOpenErr({ ...openErr, open: true });
      });
  }

  async function handleFilesUpload(e, state, setState) {
    const file = e.target.files[0];

    setOpen(true);
    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      }
    )
      .then((result) => {
        Storage.get(result.key)
          .then((res) => {
            let temp1 = [...state];
            let temp = {};
            temp.id = result.key;
            temp.name = result.key;
            temp.type = file?.type.substring(0, file?.type.indexOf("/"));
            temp.url = res.substring(0, res.indexOf("?"));
            temp1.push(temp);
            setState(temp1);
            setOpen(false);
          })
          .catch((e) => setOpen(false));
      })
      .catch((err) => {
        setOpen(false);
        setError("Upload Failed, Try Different Image");
        setOpenErr({ ...openErr, open: true });
      });
  }

  async function handleCertUpload(e, index) {
    const file = e.target.files[0];
    setOpen(true);
    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      }
    )
      .then((result) => {
        Storage.get(result.key)
          .then((res) => {
            let temp = [...certificates];
            temp[index].type = file?.type.substring(0, file?.type.indexOf("/"));
            temp[index].url = res.substring(0, res.indexOf("?"));
            temp[index].id = result.key;
            setCertificates(temp);
            setOpen(false);
          })
          .catch((e) => setOpen(false));
      })
      .catch((err) => {
        setOpen(false);
        setError("Upload Failed, Try Different Image");
        setOpenErr({ ...openErr, open: true });
      });
  }

  const handleAddress1Change = (address_line1, values, setFieldValue) => {
    setFieldValue("address_line1", address_line1);
  };

  const handleAddressSelect = async (values, placeId, setFieldValue) => {
    const selectedAddress = await geocodeByPlaceId(placeId);
    const LatLong = await getLatLng(selectedAddress[0]);
    let street_number = "";
    let sub_locality1 = "";
    let sub_locality2 = "";
    let sub_locality3 = "";
    let route = "";
    let city = "";
    let state = "";
    let zip = "";
    let country = "";
    let location = {};
    if (LatLong) {
      location.latitude = LatLong.lat;
      location.longitude = LatLong.lng;
    }
    !!selectedAddress[0].address_components.length &&
      selectedAddress[0].address_components.forEach((address) => {
        console.log(address);
        if (address.types.includes("street_number"))
          street_number = address.short_name;
        if (address.types.includes("sublocality_level_3"))
          sub_locality1 = address.short_name;
        if (address.types.includes("sublocality_level_2"))
          sub_locality2 = address.short_name;
        if (address.types.includes("sublocality_level_1"))
          sub_locality3 = address.short_name;
        if (address.types.includes("route")) route = address.short_name;
        if (address.types.includes("locality")) city = address.short_name;
        if (address.types.includes("administrative_area_level_1"))
          state = address.long_name;
        if (address.types.includes("country")) country = address.long_name;
        if (address.types.includes("postal_code")) zip = address.long_name;
      });

    setFieldValue(
      "address_line1",
      street_number
        ? `${street_number} ${route}`
        : `${sub_locality1} ${sub_locality2} ${sub_locality3} ${route}`
    );
    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("postal_code", zip);
    setFieldValue("latitude", location.latitude);
    setFieldValue("longitude", location.longitude);
    setFieldValue("country", country);
  };

  const signOut = async () => {
    await Auth.signOut({ global: true })
      .then((res) => {
        navigate("/login");
      })
      .then((res) => console.log(res))
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((res) => {
      API.graphql(
        graphqlOperation(listSellerUsers, {
          filter: { userId: { eq: res.username } },
        })
      )
        .then((res) => {
          API.graphql({
            query: getSeller,
            variables: {
              id: res?.data?.listSellerUsers?.items[0].sellerId,
            },
          })
            .then((res) => {
              setLoading(false);
              setSeller(res.data?.getSeller);
              if (res?.data?.getSeller?.verified === true) {
                navigate("/");
              }
            })
            .catch((e) => console.log(e, "sellerError"));
        })
        .catch((e) => console.log(e, "listError"));
    });
  }, []);

  if (loading) return <CircularProgress className="loader" />;
  if (seller.verified === false)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 30,
          gap: 2,
        }}
      >
        <img src="images/logo.png" alt="wtx_logo" />
        <h2>Your profile has been rejected. </h2>
        <h4>
          Please edit your account details.Admin will review and verify your
          account soon!
        </h4>
        <h4>
          Contact us at{" "}
          <a
            href="mailto:email@example.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            email@example.com
          </a>
        </h4>
        <Box sx={{ display: "flex", gap: 2 }}>
          <SubmitBtn onClick={() => navigate("/edit_info")}>
            Edit Profile
          </SubmitBtn>
          <SubmitBtn onClick={signOut}>Logout</SubmitBtn>
        </Box>
      </Box>
    );

  if (seller?.phone)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 30,
          gap: 2,
        }}
      >
        <img src="images/logo.png" alt="wtx_logo" />
        <h2>Thanks for Updating Your Profile </h2>
        <h4>Admin will review and verify your account soon!</h4>
        <h4>
          Contact us at{" "}
          <a
            href="mailto:email@example.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            email@example.com
          </a>
        </h4>
        <Box sx={{ display: "flex", gap: 2 }}>
          <SubmitBtn onClick={() => navigate("/edit_info")}>
            Edit Profile
          </SubmitBtn>
          <SubmitBtn onClick={signOut}>Logout</SubmitBtn>
        </Box>
      </Box>
    );

  return (
    <Box sx={{ display: "flex", gap: "1rem", m: 3 }}>
      <Card
        sx={{ width: "20%", minWidth: "23rem", p: 1, borderRadius: "1rem" }}
      >
        <Card
          sx={{
            height: "300px",
            borderRadius: ".6rem",
            background: "lightblue",
          }}
        >
          <img src="/images/verify.png" alt="verify" width="100%" />
        </Card>
        <Box sx={{ m: 1, textAlign: "center" }}>
          <h2>Welcome to World Trade X</h2>
          <h3>Verify Your Account</h3>
          <SubmitBtn style={{ marginTop: ".4rem" }} onClick={signOut}>
            Logout
          </SubmitBtn>
        </Box>
      </Card>
      <Card
        sx={{
          w: "4rem",
          flex: 1,
          borderRadius: "1rem",
          height: "95vh",
          overflow: "auto",
        }}
      >
        <h1 style={{ margin: "1rem" }}>KYC Documents</h1>
        {/* <Divider /> */}
        <Box
          sx={{
            padding: "1rem",
            mt: "2rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
            border: "1px solid #edf2f9",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              updateSellerApi(values);
              setTimeout(() => {
                setSubmitting(false);
              }, 300);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form
                style={{
                  padding: "1rem",
                  mt: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".6rem",
                }}
              >
                <div>
                  <Avatar
                    alt="Travis Howard"
                    src={seller?.image}
                    sx={{
                      width: 100,
                      height: 100,
                      marginLeft: "1rem",
                      color: "#183B59",
                    }}
                  />
                  <Button>
                    <label htmlFor="image" style={{ color: "#183B59" }}></label>
                    <input
                      required
                      type="file"
                      accept="image/*"
                      id="image"
                      onChange={(e) => handleProfileImageUpload(e)}
                    />
                  </Button>
                </div>
                <Label>Business Name</Label>
                <Field
                  as={TextField}
                  type="text"
                  name="name"
                  placeholder="e.g World Trade X"
                  disabled
                />
                <Label>Business Email</Label>
                <Field
                  as={TextField}
                  type="email"
                  name="email"
                  placeholder="e.g alex.worldtradex.com"
                  disabled
                />
                <Label>Business Phone*</Label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  country={"us"}
                  value={values.phone}
                  onChange={(phone) => setFieldValue("phone", "+" + phone)}
                />
                <h3>Bank Details</h3>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr ",
                    gap: ".7rem",
                    mb: 2,
                    mt: 2,
                  }}
                >
                  <Label>Bank Name*</Label>
                  <Label>Account Name*</Label>
                  <Field
                    required
                    as={TextField}
                    type="text"
                    name="bankName"
                    placeholder="e.g Bank of America"
                  />
                  <Field
                    required
                    as={TextField}
                    type="text"
                    name="accountName"
                    placeholder="e.g John Doe"
                  />
                  <Label>Account Number*</Label>
                  <Label>Routing Number*</Label>
                  <Field
                    required
                    as={NumericFormat}
                    type="text"
                    name="accountNumber"
                    placeholder="e.g 797979797979"
                    maxLength={12}
                    style={numStyle}
                  />
                  <Field
                    required
                    as={NumericFormat}
                    type="text"
                    name="routingNumber"
                    placeholder="e.g 797798798"
                    maxLength={9}
                    style={numStyle}
                  />
                </Box>
                <h3>Business Registered Address</h3>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: ".7rem",
                    mt: 2,
                  }}
                >
                  <Label>Address Line 1*</Label>
                  <Label>Address Line 2</Label>
                  <Label>State</Label>

                  <PlacesAutocomplete
                    name="address_line1"
                    value={values.address_line1}
                    onChange={(data) => {
                      handleAddress1Change(data, values, setFieldValue);
                    }}
                    onSelect={(values, placeId) =>
                      handleAddressSelect(values, placeId, setFieldValue)
                    }
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ position: "relative" }}>
                        <Field
                          as={TextField}
                          required
                          name="address_line1"
                          placeholder="address1"
                          sx={{ width: "100%" }}
                          {...getInputProps({
                            className: "location-search-input",
                          })}
                          autoComplete="off"
                        />
                        <div
                          className="autocomplete-dropdown-container"
                          style={{
                            position: "absolute",
                            width: "90%",
                            background: "white",
                            zIndex: "222",
                          }}
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                })}
                                style={{
                                  background: "#fafafa",
                                  marginTop: ".2rem",
                                  padding: ".4rem",
                                  cursor: "pointer",
                                }}
                                key={index}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <Field
                    as={TextField}
                    type="text"
                    name="address_line2"
                    placeholder="e.g Cilvet Street"
                  />
                  <Field
                    as={TextField}
                    type="text"
                    name="state"
                    placeholder="e.g Cilvet Street"
                  />
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr ",
                    gap: ".7rem",
                  }}
                >
                  <Label>City</Label>
                  <Label>Country</Label>
                  <Label>Zip Code</Label>

                  <Field
                    as={TextField}
                    type="text"
                    name="city"
                    placeholder="e.g Austin"
                  />
                  <Field
                    as={TextField}
                    type="text"
                    name="country"
                    placeholder="e.g Texas"
                  />
                  <Field
                    as={TextField}
                    type="text"
                    name="postal_code"
                    placeholder="e.g 78701"
                    maxLength={6}
                  />
                </Box>
                <Label>About the Company*</Label>
                <Field
                  as={TextareaAutosize}
                  required
                  name="about"
                  minRows={6}
                  maxLength={500}
                  style={textFieldStyle}
                  placeholder="Write about your company in 500 words."
                />

                <Label>Company Images and videos</Label>
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {images && images?.length < 6 && (
                    <>
                      <label htmlFor="company-image" className="img-upload">
                        <CloudUploadIcon />
                      </label>
                      <input
                        type="file"
                        accept="image/*,video/*"
                        id="company-image"
                        hidden
                        onChange={(e) => {
                          handleFilesUpload(e, images, setImages);
                        }}
                      />
                    </>
                  )}
                  {images &&
                    images.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          padding: ".4rem",
                          display: "flex",
                          borderRadius: ".4rem",
                          marginBottom: ".1rem",
                          position: "relative",
                        }}
                      >
                        {item?.type === "video" ? (
                          <video
                            width={100}
                            height={100}
                            autoPlay
                            style={{ borderRadius: "10px" }}
                            muted
                          >
                            <source src={item?.url} />{" "}
                          </video>
                        ) : (
                          <img
                            src={item?.url}
                            alt="prod images"
                            width="100px"
                            height="90px"
                            style={{
                              objectFit: "cover",
                              borderRadius: ".6rem",
                            }}
                          />
                        )}

                        <ClearIcon
                          sx={clrIcon}
                          onClick={() => {
                            Storage.remove(item.name).then(() => {
                              let temp = images.filter(
                                (item, i) => i !== index
                              );
                              setImages(temp);
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>

                <Label>Images of Awards and Recognitions</Label>
                <div style={{ display: "flex", gap: "1rem" }}>
                  {awards && awards?.length < 6 && (
                    <>
                      <label htmlFor="company-image1" className="img-upload">
                        <CloudUploadIcon />
                      </label>
                      <input
                        type="file"
                        accept="image/*,.pdf"
                        id="company-image1"
                        hidden
                        onChange={(e) => {
                          handleFilesUpload(e, awards, setAwards);
                        }}
                      />
                    </>
                  )}
                  {awards &&
                    awards.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          padding: ".4rem",
                          display: "flex",
                          borderRadius: ".4rem",
                          marginBottom: ".1rem",
                          position: "relative",
                        }}
                      >
                        {item?.type === "image" ? (
                          <img
                            src={item?.url}
                            width="100px"
                            height="90px"
                            style={{ objectFit: "contain" }}
                          />
                        ) : (
                          <a href={item?.url} target="blank">
                            <img
                              src="/images/pdf.png"
                              width="100px"
                              height="90px"
                              style={{ objectFit: "contain" }}
                            />
                          </a>
                        )}

                        <ClearIcon
                          sx={clrIcon}
                          onClick={() => {
                            Storage.remove(item.name).then(() => {
                              let temp = awards.filter(
                                (item, i) => i !== index
                              );
                              setAwards(temp);
                            });
                          }}
                        />
                      </div>
                    ))}
                </div>
                <div className="certificate-container">
                  {certificates.map((item, index) => (
                    <div className="certificate" key={index}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>Business Registration Certificates*</Label>
                        {certificates?.length > 1 && (
                          <Delete
                            className="delete"
                            onClick={() => {
                              let temp = [...certificates];
                              temp = temp.filter((item, i) => i !== index);
                              setCertificates(temp);
                            }}
                          />
                        )}
                      </div>
                      <Select
                        required
                        size="small"
                        name="certificate"
                        value={item.name}
                        onChange={(e) => {
                          let temp = [...certificates];
                          temp[index].name = e.target.value;
                          setCertificates(temp);
                        }}
                      >
                        <MenuItem value="Trade Cetrificate">
                          Trade Cetrificate
                        </MenuItem>
                        <MenuItem value="FSSAI Certificate">
                          FSSAI Certificate
                        </MenuItem>
                      </Select>
                      <Label>
                        Upload Document*
                        <Tooltip
                          sx={{ color: "gray", height: "15px" }}
                          title={
                            <div style={{ fontSize: ".9rem" }}>
                              <p>Document Must be less than 5MB</p>
                            </div>
                          }
                        >
                          <InfoRounded />
                        </Tooltip>
                      </Label>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          required
                          type="file"
                          accept="image/*,.pdf"
                          onChange={(e) => {
                            handleCertUpload(e, index);
                          }}
                        />
                        {certificates[index].url !== "" && (
                          <>
                            {item?.type === "image" ? (
                              <img
                                src={item?.url}
                                height="60px"
                                width="60px"
                                style={{ objectFit: "contain" }}
                              />
                            ) : (
                              <a href={item?.url} target="blank">
                                <img
                                  src="/images/pdf.png"
                                  height="30px"
                                  width="30px"
                                  style={{ objectFit: "contain" }}
                                />
                              </a>
                            )}
                          </>
                        )}
                      </div>
                      <Label>Certificate Number</Label>
                      <TextField
                        size="small"
                        name="certificate"
                        value={item.no}
                        onChange={(e) => {
                          let temp = [...certificates];
                          temp[index].name.concat(":" + e.target.value);
                          setCertificates(temp);
                        }}
                      />
                    </div>
                  ))}

                  <button
                    className={
                      certificates[certificates?.length - 1].name !== ""
                        ? "add-cert-btn"
                        : "add-cert-btn btn-disabled"
                    }
                    type="button"
                    onClick={() => {
                      handleAddMoreCert();
                    }}
                  >
                    +Add More
                  </button>
                </div>
                <SubmitBtn type="submit">Update</SubmitBtn>
              </Form>
            )}
          </Formik>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <SnackBar
          text={"KYC has been Updated"}
          email={""}
          state={bar}
          setState={setBar}
        />{" "}
        <ErrorBar
          text={error}
          email={""}
          state={openErr}
          setState={setOpenErr}
        />
      </Card>
    </Box>
  );
}
