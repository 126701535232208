import { Delete } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Backdrop,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  clrIcon,
  CustomDiv,
  errorMsg,
  keywordDiv,
  keywordInput,
  Label,
  StyledCard,
  SubmitBtn,
  textFieldStyle,
  UploadDiv,
} from "../components/styles";
import { updateProduct } from "../graphql/mutations";
import { getProduct } from "../graphql/queries";
import { useSelector } from "react-redux";
import ErrorBar from "../components/ErrorBar";

export default function StyledDropzone(props) {
  const regex = /^(\d*\.?\d{0,2})/;
  const seller = useSelector((state) => state?.sellerInfo?.payload);
  const navigate = useNavigate();
  const [prodData, setProdData] = useState([]);
  const { ID } = useParams();
  const [prodError, setProdError] = useState(null);
  const [prodCertificates, setProdCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(Math.random().toString(36).substring(2, 15));
  const [newAttr, setNewAttr] = useState({
    id: id,
    name: "",
    value: "",
    notEditable: true,
  });
  const [characters, updateCharacters] = useState([]);
  const [tierPrice, setTierPrice] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [error1, setError1] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [newPriceRange, setNewPriceRange] = useState({
    minQty: "",
    maxQty: "",
    price: "",
    discountPrice: "",
  });
  const [disable, setDisable] = useState(false);
  const [units, setUnits] = useState([]);

  const [openErr, setOpenErr] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [open, setOpen] = useState(false);
  // QUERIES
  const getProduct = /* GraphQL */ `
    query GetProduct($id: ID!) {
      getProduct(id: $id) {
        id
        name
        description
        image
        images
        documents
        listPrice
        unitPrice
        tierPrice
        unitType
        attributes
        active
        verified
        taxExempt
        keywords
        leadTime
        rating
        stock
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        createdAt
        productCategoryId
        productCategory {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        productSubCategoryId
        productSubCategory {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        createdAt
        feedbacks {
          nextToken
        }
        updatedAt
      }
    }
  `;
  // QUERIES END

  // MUTATIONS
  const updateProduct = /* GraphQL */ `
    mutation UpdateProduct(
      $input: UpdateProductInput!
      $condition: ModelProductConditionInput
    ) {
      updateProduct(input: $input, condition: $condition) {
        id
        name
        description
        image
        images
        documents
        listPrice
        unitPrice
        tierPrice
        unitType
        attributes
        active
        verified
        taxExempt
        keywords
        leadTime
        rating
        stock
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        createdAt
        productCategoryId
        productCategory {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        productSubCategoryId
        productSubCategory {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        createdAt
        feedbacks {
          nextToken
        }
        updatedAt
      }
    }
  `;
  // MUTATIONS END
  const handleAddAttribute = () => {
    setId(Math.random().toString(36).substring(2, 15));
    setError("");
    let temp = [...characters];
    if (newAttr.name !== "" && newAttr.value !== "") {
      temp.push(newAttr);
      updateCharacters(temp);
      setNewAttr({
        id: Math.random().toString(36).substring(2, 15),
        name: "",
        value: "",
        notEditable: true,
      });
    } else {
      setError("Fields are required*");
    }
  };

  const handleAddPriceRange = () => {
    setError1("");
    if (
      newPriceRange.minQty !== "" &&
      newPriceRange.maxQty !== "" &&
      newPriceRange.price !== ""
    ) {
      setTierPrice([...tierPrice, newPriceRange]);
      setNewPriceRange({
        minQty: "",
        maxQty: "",
        price: "",
        discountPrice: "",
      });
    } else {
      setError1("Fields are required*");
    }
  };

  async function handleFileUpload(e) {
    const file = e.target.files[0];
    // if (file.size > 5242880) {
    //   setUploadError("Max file Size is 5MB");
    //   return;
    // }
    let type = file?.type.substring(0, file?.type.indexOf("/"));
    if (type !== "image") {
      setUploadError("Only Images are allowed !");
      setOpenErr({ ...openErr, open: true });
      return;
    }
    setOpen(true);

    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
      }
    )
      .then((result) => {
        Storage.get(result.key, { level: "public" }).then((res) => {
          let temp = { ...prodData };
          temp.image = res.substring(0, res.indexOf("?"));
          setProdData(temp);
          setOpen(false);
        });
      })
      .catch((err) => {
        setOpen(false);
        setUploadError("Upload Failed, Try Different Image.");
      });
  }

  async function handleFilesUpload(e, state, setState) {
    const file = e.target.files[0];
    // if (file.size > 5242880) {
    //   setUploadError("Max file Size is 5MB");
    //   return;
    // }

    setOpen(true);
    await Storage.put(
      Math.random().toString(36).substring(2, 15) +
        "." +
        file?.type.substring(file?.type.indexOf("/")).substring(1),
      file,
      {
        contentType: file?.type,
      }
    )
      .then((result) => {
        Storage.get(result.key)
          .then((res) => {
            let temp1 = [...state];
            let temp = {};
            temp.id = result.key;
            temp.name = result.key;
            temp.type = file?.type.substring(0, file?.type.indexOf("/"));
            temp.url = res.substring(0, res.indexOf("?"));
            temp1.push(temp);
            setState(temp1);
            setOpen(false);
          })
          .catch((e) => setOpen(false));
      })
      .catch((err) => {
        setOpen(false);
        setOpenErr({ ...openErr, open: true });
        setUploadError("Upload Failed, Try Different Image");
      });
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    API.graphql({
      query: updateProduct,
      variables: {
        input: {
          id: ID,
          sellerId: seller?.id,
          name: prodData.name,
          description: prodData.description,
          image: prodData.image,
          images: JSON.stringify(files),
          listPrice: Number(prodData.listPrice),
          unitPrice: Number(prodData.unitPrice),
          tierPrice: JSON.stringify(tierPrice),
          unitType: prodData?.unitType,
          attributes: JSON.stringify(characters),
          taxExempt: prodData?.taxExempt,
          keywords: prodData.keywords,
          leadTime: prodData?.leadTime,
          stock: prodData?.stock,
          documents: JSON.stringify(prodCertificates),
        },
      },
    })
      .then((res) => {
        navigate("/products");
        console.log(res);
      })
      .catch((e) => setProdError(e.message));
  }

  useEffect(() => {
    async function getProd() {
      API.graphql({
        query: getProduct,
        variables: {
          id: ID,
        },
      }).then((res) => {
        setLoading(false);
        setProdData(res.data.getProduct);
        let temp = JSON.parse(res?.data.getProduct?.attributes);
        updateCharacters(temp);
        setFiles(JSON.parse(res?.data?.getProduct?.images));
        setProdCertificates(JSON.parse(res?.data?.getProduct?.documents));
        setTierPrice(JSON.parse(res?.data?.getProduct?.tierPrice));
      });
    }
    getProd();
  }, [ID]);

  useEffect(() => {
    const getParentEnum = `
    query GetParentEnum {
     __type(name: "UnitType") {
     enumValues {
     name
    }
    }
     }
    `;

    API.graphql(graphqlOperation(getParentEnum)).then((response) => {
      let temp = response.data.__type.enumValues.map(
        (enumValue) => enumValue.name
      );
      setUnits(temp);
      console.log(temp);
    });
  }, []);

  useEffect(() => {
    +prodData?.unitPrice < +prodData.listPrice
      ? setDisable(true)
      : setDisable(false);

    if (tierPrice && tierPrice.length === 0) {
      setDisable(true);
    }
  }, [prodData?.unitPrice, prodData.listPrice, tierPrice]);

  // useEffect(() => {
  //   dates.mfgDate > dates.expDate ? setDisable(true) : setDisable(false);
  // }, [dates]);

  if (loading) return <CircularProgress className="loader" />;

  return (
    <Box className="add-a-product-card">
      <div className="header-container">
        <div
          className="header"
          style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
        >
          <Link to="/products">
            <ArrowBackIosIcon sx={{ fontSize: "medium", marginTop: ".3rem" }} />
          </Link>
          <h1>Edit Product</h1>
        </div>
      </div>
      <Divider />
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <StyledCard className="add-a-prod-container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <Label>Select Category</Label>
            <Label>Select Sub Category</Label>
            <TextField
              value={prodData?.productCategory?.name || "Others"}
              disabled
            />
            <TextField
              value={prodData?.productSubCategory?.name || "Others"}
              disabled
            />
          </div>
          <Label>Product Name*</Label>
          <TextField
            required
            value={prodData.name || ""}
            onChange={(e) => {
              let temp = { ...prodData };
              temp.name = e.target.value;
              setProdData(temp);
            }}
          />
          <Label>Product Hero Image*</Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => handleFileUpload(e)}
            />
            <img src={prodData.image} alt="heroImg" width="60px" />
          </div>

          <Label>Other Images and Videos</Label>
          <div style={{ display: "flex", gap: "1rem" }}>
            {prodData.length !== 0 &&
              JSON.parse(prodData?.images).length < 6 && (
                <>
                  <label htmlFor="image" className="img-upload">
                    <CloudUploadIcon />
                  </label>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    hidden
                    id="image"
                    onChange={(e) => handleFilesUpload(e, files, setFiles)}
                  />
                </>
              )}
            {files?.length !== 0 &&
              files?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    padding: ".4rem",
                    display: "flex",
                    borderRadius: ".4rem",
                    marginBottom: ".1rem",
                    position: "relative",
                  }}
                >
                  <div key={index} style={{ height: "100%" }}>
                    {item?.type == "video" ? (
                      <video width={100} height={100} autoPlay muted>
                        <source src={item?.url} />{" "}
                      </video>
                    ) : (
                      <img
                        src={item?.url}
                        alt="prod images"
                        width="100px"
                        height="90px"
                        style={{
                          objectFit: "cover",
                          borderRadius: ".6rem",
                        }}
                      />
                    )}
                  </div>

                  <ClearIcon
                    sx={clrIcon}
                    onClick={() => {
                      let temp = files.filter((item, i) => i !== index);
                      setFiles(temp);
                    }}
                  />
                </div>
              ))}
          </div>
          <Label>Product Description*</Label>
          <TextareaAutosize
            required
            value={prodData.description || ""}
            onChange={(e) => {
              let temp = { ...prodData };
              temp.description = e.target.value;
              setProdData(temp);
            }}
            minRows={6}
            maxLength={500}
            style={textFieldStyle}
          />
          <div
            style={{
              borderRadius: ".4rem",
              border: "2px solid lightgray",
            }}
          >
            <div style={{ padding: "1.6rem" }}>
              <Label>Key Specification</Label>
              <p
                style={{
                  fontSize: ".8rem",
                  color: "lightgray",
                  marginTop: ".5rem",
                }}
              >
                Product attributes are quantifiable or descriptive aspects of a
                product (such as, color). For example, if you were to create an
                attribute for color, with the values of blue, green, yellow, and
                so on, you may want to apply this attribute to shirts, which you
                sell in various colors (you can adjust a price or weight for any
                of existing attribute values). You can add attribute for your
                product using existing list of attributes, or if you need to
                create a new one
              </p>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                  {(provided) => (
                    <div
                      style={{ marginTop: "1.6rem" }}
                      className="characters"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {characters.length !== 0 &&
                        characters.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              className="dragItem"
                            >
                              {(provided) => (
                                <div
                                  className="date-box"
                                  key={item.id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TextField
                                    value={item.name}
                                    disabled={!item.notEditable}
                                    onChange={(e) => {
                                      let temp = [...characters];
                                      let temp2 = { ...temp[index] };
                                      temp2.name = e.target.value;
                                      temp[index] = temp2;
                                      updateCharacters(temp);
                                    }}
                                  />

                                  {item.type === "date" ? (
                                    <input
                                      type="date"
                                      value={item.value}
                                      onChange={(e) => {
                                        let temp = [...characters];
                                        let temp2 = { ...temp[index] };
                                        temp2.value = e.target.value;
                                        temp[index] = temp2;
                                        updateCharacters(temp);
                                      }}
                                      style={{
                                        fontSize: "1.1rem",
                                        padding: ".4rem",
                                        borderRadius: ".4rem",
                                        border: "1.5px solid lightgray",
                                        height: "3.9rem",
                                        outlineColor: "#1976D2",
                                        fontFamily: "inter",
                                      }}
                                    />
                                  ) : (
                                    <TextField
                                      // disabled={item.notEditable}
                                      value={item.value}
                                      onChange={(e) => {
                                        let temp = [...characters];
                                        let temp2 = { ...temp[index] };
                                        temp2.value = e.target.value;
                                        temp[index] = temp2;
                                        updateCharacters(temp);
                                      }}
                                    />
                                  )}

                                  <DragIndicatorIcon
                                    style={{
                                      color: "#265075",
                                    }}
                                  />
                                  {item.notEditable && (
                                    <Delete
                                      style={{
                                        color: "#265075",
                                        cursor: "pointer",
                                        fontSize: "25px",
                                      }}
                                      onClick={() => {
                                        let temp = [...characters];
                                        temp = temp.filter(
                                          (item, i) => i !== index
                                        );
                                        updateCharacters(temp);
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr .1fr .1fr",
                gap: "1rem",
                padding: "1.6rem",
                background: "#B8BEC52B ",
              }}
            >
              {error && (
                <p style={{ gridColumn: "span 4", color: "red" }}>{error}</p>
              )}
              <TextField
                placeholder="Attribute e.g Color"
                value={newAttr.name}
                onChange={(e) =>
                  setNewAttr({ ...newAttr, name: e.target.value })
                }
              />
              <TextField
                placeholder="Value e.g Green"
                value={newAttr.value}
                onChange={(e) =>
                  setNewAttr({ ...newAttr, value: e.target.value })
                }
              />
              <span></span>
              <span></span>
              <span></span>

              <div
                style={{
                  display: "flex",
                  justifySelf: "end",
                  justifyContent: "end",
                }}
              >
                <SubmitBtn type="button" onClick={handleAddAttribute}>
                  {" "}
                  Add
                </SubmitBtn>
              </div>
            </div>
          </div>
          <Label>Product Keywords</Label>
          <CustomDiv>
            {prodData.keywords &&
              prodData.keywords.map((item, index) => (
                <div key={index}>
                  <div style={keywordDiv}>
                    <p>{item}</p>
                    <ClearIcon
                      sx={{
                        fontSize: ".9rem",
                        cursor: "pointer",
                        border: "1px solid gray",
                        borderRadius: "50%",
                      }}
                      onClick={() => {
                        let temp = { ...prodData };
                        let temp2 = [...temp.keywords];
                        temp2 = temp2.filter((item, i) => i !== index);
                        temp.keywords = temp2;
                        setProdData(temp);
                      }}
                    />
                  </div>
                </div>
              ))}{" "}
            <input
              style={keywordInput}
              placeholder="Enter Keywords"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.value !== "") {
                  e.preventDefault();
                  let temp = { ...prodData };
                  let temp2 = [...temp.keywords];
                  temp2.push(e.target.value);
                  temp.keywords = temp2;
                  setProdData(temp);
                  e.target.value = "";
                }
              }}
            />
          </CustomDiv>

          <h2 style={{ marginTop: "2rem" }}>Trade Information</h2>
          <Label>Unit type*</Label>
          <Select
            required
            value={prodData?.unitType || ""}
            onChange={(e) => {
              let temp = { ...prodData };
              temp.unitType = e.target.value;
              setProdData(temp);
            }}
          >
            {units.map((unit, index) => (
              <MenuItem key={index} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>

          <Label>Available Quantity*</Label>
          <TextField
            required
            type="number"
            value={prodData?.stock}
            onChange={(e) => {
              let temp = { ...prodData };
              temp.stock = e.target.value.match(regex)[1];
              setProdData(temp);
            }}
          />

          {tierPrice === null && (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <Label>Actual Price</Label>
                <Label>Discounted Price</Label>
                <TextField
                  required
                  value={prodData.unitPrice}
                  type="number"
                  onChange={(e) => {
                    let temp = { ...prodData };
                    temp.unitPrice = e.target.value.match(regex)[1];
                    setProdData(temp);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">| $</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  value={prodData.listPrice}
                  type="number"
                  onChange={(e) => {
                    let temp = { ...prodData };
                    temp.listPrice = e.target.value.match(regex)[1];
                    setProdData(temp);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">| $</InputAdornment>
                    ),
                  }}
                />
              </div>
              {+prodData?.unitPrice < +prodData?.listPrice && (
                <p style={errorMsg}>
                  Discounted Price must be less than Actual Price
                </p>
              )}
            </>
          )}
          {tierPrice !== null && (
            <>
              <Label>Price Ranges</Label>
              <div
                style={{
                  borderRadius: ".4rem",
                  border: "2px solid lightgray",
                }}
              >
                <div style={{ padding: "1.6rem" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr .3fr",
                      gap: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Label>Minimum Units*</Label>
                    <Label>Maximum Units*</Label>
                    <Label>Actual Price*</Label>
                    <Label>Discounted Price</Label>
                  </div>
                  {tierPrice &&
                    tierPrice.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr .3fr",
                          gap: "1rem",
                          marginBottom: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          value={item.minQty}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | {prodData?.unitType || ""}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...tierPrice];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].minQty = customValue;
                            setTierPrice(temp);
                          }}
                        />
                        <TextField
                          value={item.maxQty}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | {prodData?.unitType || ""}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...tierPrice];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].maxQty = customValue;
                            setTierPrice(temp);
                          }}
                        />
                        <TextField
                          value={item.price}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | $
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...tierPrice];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].price = customValue;
                            setTierPrice(temp);
                          }}
                        />
                        <TextField
                          value={item.discountPrice}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                | $
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            let temp = [...tierPrice];
                            let customValue = e.target.value.match(regex)[1];
                            if (e.target.value !== "")
                              customValue = parseInt(customValue);
                            temp[index].discountPrice = customValue;
                            setTierPrice(temp);
                          }}
                        />

                        <Delete
                          style={{
                            color: "#265075",
                            cursor: "pointer",
                            fontSize: "25px",
                          }}
                          onClick={() => {
                            let temp = [...tierPrice];
                            temp = temp.filter((item, i) => i !== index);
                            setTierPrice(temp);
                          }}
                        />
                      </div>
                    ))}
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr .3fr",
                    gap: "1rem",
                    padding: "1.6rem",
                    background: "#B8BEC52B ",
                  }}
                >
                  {error1 && (
                    <p style={{ gridColumn: "span 5", color: "red" }}>
                      {error1}
                    </p>
                  )}
                  <TextField
                    required={!tierPrice.length}
                    placeholder="e.g 100"
                    value={newPriceRange.minQty}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          | {prodData?.unitType}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setNewPriceRange({
                        ...newPriceRange,
                        minQty: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required={!tierPrice.length}
                    placeholder="e.g 200"
                    value={newPriceRange.maxQty}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          | {prodData?.unitType}
                        </InputAdornment>
                      ),
                    }}
                    type="number"
                    onChange={(e) =>
                      setNewPriceRange({
                        ...newPriceRange,
                        maxQty: e.target.value,
                      })
                    }
                  />
                  <TextField
                    required={!tierPrice.length}
                    placeholder="e.g 10"
                    value={newPriceRange.price}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">| $</InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setNewPriceRange({
                        ...newPriceRange,
                        price: e.target.value,
                      })
                    }
                  />
                  <TextField
                    placeholder="e.g 10"
                    value={newPriceRange.discountPrice}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">| $</InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setNewPriceRange({
                        ...newPriceRange,
                        discountPrice: e.target.value,
                      })
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      justifySelf: "end",
                      justifyContent: "end",
                      gridColumn: "span 4",
                    }}
                  >
                    <SubmitBtn type="button" onClick={handleAddPriceRange}>
                      {" "}
                      Add
                    </SubmitBtn>
                  </div>
                </div>
                {+newPriceRange.price < +newPriceRange.discountPrice && (
                  <p style={errorMsg}>
                    Discounted Price must be less than actual Price
                  </p>
                )}
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            <div>
              <Label>Tax exempt</Label>
              <p
                style={{
                  fontSize: ".8rem",
                  color: "gray",
                }}
              >
                Determines whether this product is tax exempt (tax will not be
                applied to this product at checkout).
              </p>
            </div>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                let temp = { ...prodData };
                temp.taxExempt = !temp.taxExempt;
                setProdData(temp);
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                checked={prodData?.taxExempt}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                checked={!prodData?.taxExempt}
              />
            </RadioGroup>
          </div>
          <Label>Product Certificates</Label>
          <div style={{ display: "flex", gap: "1rem" }}>
            {prodCertificates?.length < 6 && (
              <>
                <label htmlFor="image1" className="img-upload">
                  <CloudUploadIcon />
                </label>
                <input
                  type="file"
                  hidden
                  id="image1"
                  accept="image/*,.pdf"
                  onChange={(e) =>
                    handleFilesUpload(e, prodCertificates, setProdCertificates)
                  }
                />
              </>
            )}
            {prodCertificates?.map((item, index) => (
              <UploadDiv key={index}>
                {item?.type === "image" ? (
                  <img
                    src={item?.url}
                    height="90px"
                    width="100px"
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <a href={item?.url} target="blank">
                    <img
                      src="/images/pdf.png"
                      height="90px"
                      width="100px"
                      style={{ objectFit: "contain" }}
                    />
                  </a>
                )}

                <ClearIcon
                  style={clrIcon}
                  onClick={() => {
                    let temp = prodCertificates.filter(
                      (item, i) => i !== index
                    );
                    setProdCertificates(temp);
                  }}
                />
              </UploadDiv>
            ))}
          </div>

          <h2 style={{ marginTop: "2rem" }}>Logistics Information</h2>
          <Label>Expected Dispatch time (Hrs)*</Label>
          <TextField
            required
            value={prodData.leadTime || ""}
            onChange={(e) => {
              let temp = { ...prodData };
              temp.leadTime = e.target.value.match(regex)[1];
              setProdData(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">| Hrs</InputAdornment>
              ),
            }}
            type="number"
          />
          {prodError && <p style={{ color: "red" }}>{prodError}</p>}
          <SubmitBtn className={disable && "btn-disabled"} type="submit">
            Save
          </SubmitBtn>
        </StyledCard>
      </form>
      <ErrorBar
        text={uploadError}
        email={""}
        state={openErr}
        setState={setOpenErr}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

<StyledDropzone />;
