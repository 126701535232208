import { createSlice } from "@reduxjs/toolkit";

export const sellerInfoSlice = createSlice({
  name: "userInfo",
  initialState: {},
  reducers: {
    setSellerInfo: (state, action) => {
      return (state = action);
    },
  },
});

export const { setSellerInfo } = sellerInfoSlice.actions;
export default sellerInfoSlice.reducer;
