import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from "react";

export default function OrderStepper({ orderStatus }) {
  const [status, setStatus] = useState(0);

  useEffect(() => {
    orderStatus === "READY_FOR_PICKUP" && setStatus(2);
    orderStatus === "IN_TRANSIT" && setStatus(3);
    orderStatus === "DELIVERED" && setStatus(4);
    orderStatus === "RETURNED" && setStatus(6);
  }, [orderStatus]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={status} alternativeLabel>
        <Step sx={{ color: "green" }}>
          <StepLabel>
            <b style={{ fontFamily: "Inter" }}>Order Pending</b>
          </StepLabel>
        </Step>
        <Step sx={{ color: "green" }}>
          <StepLabel>
            <b style={{ fontFamily: "Inter" }}>Order Confirmed</b>
          </StepLabel>
        </Step>
        <Step sx={{ color: "green" }}>
          <StepLabel>
            <b style={{ fontFamily: "Inter" }}>Ready to Ship</b>
          </StepLabel>
        </Step>
        <Step sx={{ color: "green" }}>
          <StepLabel>
            <b style={{ fontFamily: "Inter" }}>Shipped</b>
          </StepLabel>
        </Step>
        <Step sx={{ color: "green" }}>
          <StepLabel>
            <b style={{ fontFamily: "Inter" }}>Delivered</b>
          </StepLabel>
        </Step>
        <Step sx={{ color: "green" }}>
          <StepLabel>
            <b style={{ fontFamily: "Inter" }}>Returned</b>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}
