import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "../components/Chart";
import { DashBox, DashDiv } from "../components/styles";
import { setSellerInfo } from "../store/getSellerSlice";
import { setUserInfo } from "../store/getUserSlice";

const Dashboard = () => {
  const [showLoader, setShowLoader] = useState(true);
  const seller = useSelector((state) => state.sellerInfo?.payload);
  const user = useSelector((state) => state.userInfo?.payload);
  let qty = 0;
  const dispatch = useDispatch();

  const allUsers = useSelector((state) => state.allUsersInfo?.payload);

  const [currentSeller, setCurrentSeller] = useState(seller?.id || "");

  function handleChangeSeller(e) {
    let currentUser = allUsers?.find(
      (item) => item.sellerId === e.target.value
    );

    localStorage.setItem("current_user", JSON.stringify(currentUser));
    localStorage.setItem("current_seller", JSON.stringify(currentUser?.seller));
    let currUser = localStorage.getItem("current_user");
    let currSell = localStorage.getItem("current_seller");
    dispatch(setUserInfo(JSON.parse(currUser)));
    dispatch(setSellerInfo(JSON.parse(currSell)));
  }

  let orders = useSelector((state) =>
    state?.sellerInfo?.payload?.orders?.items?.filter(
      (item) => item.orderStatus === "DELIVERED"
    )
  ).map((item) => JSON.parse(item?.items));

  for (let order of orders) {
    for (let item of order) {
      qty += +item.quantity;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 300);
  }, []);

  const ranges = ["Daily", "Weekly", "Monthly", "Yearly"];

  if (showLoader) return <CircularProgress className="loader" />;

  return (
    <div className="base-container">
      <div className="header-container">
        <h2 className="header">
          Hi , {user?.user?.name} | Your Seller : {seller?.name} | Your Role :{" "}
          {user?.role}
        </h2>
        <TextField
          label="Select Seller"
          select
          size="small"
          sx={{ minWidth: "160px" }}
          value={currentSeller || " "}
          onChange={(e) => {
            setCurrentSeller(e.target.value);
            handleChangeSeller(e);
          }}
        >
          {allUsers?.map((item) => (
            <MenuItem key={item?.id} value={item?.sellerId}>
              {item?.seller?.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Divider />
      {/* ? Analyicts start */}
      <Box sx={{ mt: 2 }}>
        {/* <TextField
          select
          size="small"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          {ranges.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField> */}

        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ my: 1 }}
        >
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <DashDiv>
                <DashBox>
                  <Avatar sx={{ width: 55, height: 55, bgcolor: "#e4e4f5" }}>
                    <img
                      src="/images/new_orders_icon.svg"
                      width={"30px"}
                      height={"30px"}
                      alt="new_orders_icon"
                    />
                  </Avatar>

                  <p
                    style={{
                      color: "#17ac66",
                      fontWeight: "bold",
                    }}
                  >
                    +0.02%
                  </p>
                </DashBox>
                <Box>
                  <h2>$322.5</h2>
                  <p style={{ color: "gray", fontSize: ".9rem" }}>
                    Gross Sales
                  </p>
                </Box>
              </DashDiv>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <DashDiv>
                <DashBox>
                  <Avatar sx={{ width: 55, height: 55, bgcolor: "#fdecda" }}>
                    {" "}
                    <img
                      src="/images/shipping_illustration.svg"
                      width={"30px"}
                      height={"30px"}
                      alt="order_illustration"
                    />
                  </Avatar>

                  <p
                    style={{
                      color: "#e94444",
                      fontWeight: "bold",
                    }}
                  >
                    -0.15%
                  </p>
                </DashBox>
                <Box>
                  <h2>{seller?.orders?.items?.length}</h2>
                  <p style={{ color: "gray", fontSize: ".9rem" }}>Orders</p>
                </Box>
              </DashDiv>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <DashDiv>
                <DashBox>
                  <Avatar sx={{ width: 55, height: 55, bgcolor: "#d9ecfb" }}>
                    {" "}
                    <img
                      src="/images/active_orders_icon.svg"
                      width={"30px"}
                      height={"30px"}
                      alt="new_orders_icon"
                    />
                  </Avatar>

                  <p
                    style={{
                      color: "#17ac66",
                      fontWeight: "bold",
                    }}
                  >
                    +0.21%
                  </p>
                </DashBox>
                <Box>
                  <h2>{seller?.products?.items?.length}</h2>
                  <p style={{ color: "gray", fontSize: ".9rem" }}>Products</p>
                </Box>
              </DashDiv>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={{ p: 2 }}>
              <DashDiv>
                <DashBox>
                  <Avatar sx={{ width: 55, height: 55, bgcolor: "#dcf3e8" }}>
                    {" "}
                    <img
                      src="/images/order_illustration.svg"
                      width={"30px"}
                      height={"30px"}
                      alt="new_orders_icon"
                    />
                  </Avatar>

                  <p
                    style={{
                      color: "#17ac66",
                      fontWeight: "bold",
                    }}
                  >
                    +0.02%
                  </p>
                </DashBox>
                <Box>
                  <h2>{qty}</h2>
                  <p style={{ color: "gray", fontSize: ".9rem" }}>
                    Products Sold
                  </p>
                </Box>
              </DashDiv>
            </Card>
          </Grid>
        </Grid>
        <Chart />
      </Box>
    </div>
  );
};

export default Dashboard;
