import { CircularProgress, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { client } from "../App";
import OrdersTable from "../components/OrdersTable";
import { SubmitBtn } from "../components/styles";

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [allowSearrch, setAllowSearch] = useState(false);
  const [orders, setOrders] = useState([]);
  const [tempOrder, setTempOrder] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ordreFilter = (type) => {
    let temp = structuredClone(tempOrder);
    temp = temp.filter((item) => item.paymentStatus !== "PENDING");

    if (type === "all") setOrders(tempOrder);
    else if (type === "OPEN")
      setOrders(temp.filter((item) => item.orderStatus === type));
    else setOrders(temp.filter((item) => item.orderStatus === type));
  };

  const currentUser = useSelector((state) => state);
  let sellerId = currentUser?.sellerInfo?.payload?.id;

  useEffect(() => {
    setLoading(true);
    if (allowSearrch) {
      const debounce = setTimeout(() => {
        client
          .initIndex("order")
          .search(search, {
            facetFilters: [[`seller.id:${sellerId}`]],
            hitsPerPage: 20000,
          })

          .then(({ hits, nbPages }) => {
            hits = hits.filter((item) => item.paymentStatus !== "PENDING");
            setOrders(hits);
            setLoading(false);
            setLoading(false);
          })
          .catch((e) => console.log(e));
      }, 800);

      return () => clearTimeout(debounce);
    }
  }, [search]);

  useEffect(() => {
    client
      .initIndex("order")
      .search(search, {
        facetFilters: [[`seller.id:${sellerId}`]],
        hitsPerPage: 20000,
      })
      .then((res) => {
        let hits = res.hits.filter((item) => item.paymentStatus !== "PENDING");
        setAllowSearch(true);
        setLoading(false);
        setOrders(hits);
        setTempOrder(hits);
      });
  }, []);

  return (
    <Box sx={{ width: "100%" }} className="base-container">
      <div className="header-container">
        <h1 className="header">Orders</h1>
      </div>

      <Box
        sx={{ borderBottom: 1, borderColor: "#e3ebf6", marginBottom: "1rem" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="All"
            {...a11yProps(0)}
            onClick={() => ordreFilter("all")}
          />
          <Tab
            label={
              <div className="count">
                New{" "}
                <p>
                  {
                    tempOrder?.filter(
                      (item) =>
                        item.orderStatus === "OPEN" &&
                        item.paymentStatus !== "PENDING"
                    ).length
                  }
                </p>
              </div>
            }
            {...a11yProps(1)}
            onClick={() => ordreFilter("OPEN")}
          />
          <Tab
            label={
              <div className="count">
                To Be Shipped{" "}
                <p>
                  {
                    tempOrder?.filter(
                      (item) => item.orderStatus === "READY_FOR_PICKUP"
                    ).length
                  }
                </p>
              </div>
            }
            {...a11yProps(2)}
            onClick={() => ordreFilter("READY_FOR_PICKUP")}
          />
          <Tab
            label={
              <div className="count">
                Shipped{" "}
                <p>
                  {
                    tempOrder?.filter(
                      (item) => item.orderStatus === "IN_TRANSIT"
                    ).length
                  }
                </p>
              </div>
            }
            {...a11yProps(3)}
            onClick={() => ordreFilter("IN_TRANSIT")}
          />
          <Tab
            label={
              <div className="count">
                Delivered{" "}
                <p>
                  {
                    tempOrder?.filter(
                      (item) => item.orderStatus === "DELIVERED"
                    ).length
                  }
                </p>
              </div>
            }
            {...a11yProps(4)}
            onClick={() => ordreFilter("DELIVERED")}
          />
          <Tab
            label={
              <div className="count">
                Returned{" "}
                <p>
                  {
                    tempOrder?.filter((item) => item.orderStatus === "RETURNED")
                      .length
                  }
                </p>
              </div>
            }
            {...a11yProps(5)}
            onClick={() => ordreFilter("RETURNED")}
          />
          {/* <Tab
            label="Rejected"
            {...a11yProps(5)}
            onClick={() => ordreFilter("DRAFT")}
          /> */}
          <Tab
            label={
              <div className="count">
                Cancelled{" "}
                <p>
                  {
                    tempOrder?.filter(
                      (item) => item.orderStatus === "CANCELLED"
                    ).length
                  }
                </p>
              </div>
            }
            {...a11yProps(5)}
            onClick={() => ordreFilter("CANCELLED")}
          />
        </Tabs>
      </Box>
      <div className="filter-container">
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          size="small"
          sx={{ width: "20%" }}
          placeholder="Search Orders by Items"
        />
        <SubmitBtn onClick={() => setSearch("")}>Reset</SubmitBtn>
      </div>

      {loading && <CircularProgress className="loader" />}
      {!loading && (
        <>
          {tempOrder?.length === 0 ? (
            <div className="no-items">
              <img src="/images/noProds.svg" alt="" width="300px" />
              <h2>No Products</h2>
            </div>
          ) : (
            <OrdersTable orders={orders} />
          )}
        </>
      )}
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
