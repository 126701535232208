import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Amplify, Auth } from "aws-amplify";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import awsConfig from "./aws-exports";
import Protected from "./components/Protected";
import {
  Dashboard,
  EditProduct,
  EditProfile,
  Home,
  Login,
  OrderDetails,
  Orders,
  OrderStatus,
  PostAProduct,
  PreviewProduct,
  Products,
  Profile,
  Reset,
  Rfq,
  Signup,
  Users,
  Verify,
} from "./pages";
import EditData from "./pages/EditData";
import algoliasearch from "algoliasearch";
import TermsAndConditions from "./pages/Terms/TermsAndConditions";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

export const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#183B59",
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Protected Component={Home} />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="products/postProduct" element={<PostAProduct />} />
            <Route path="products" element={<Products />} />
            <Route path="Orders" element={<Orders />} />
            <Route path="profile" element={<Profile />} />
            <Route path="profile/edit" element={<EditProfile />} />
            <Route path="users" element={<Users />} />
            <Route path="rfq" element={<Rfq />} />
            <Route path="orders/:orderID" element={<OrderDetails />} />
            <Route path="/orders/status/:ID" element={<OrderStatus />} />
            <Route path="products/view/:ID" element={<PreviewProduct />} />
            <Route path="products/edit/:ID" element={<EditProduct />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/edit_info" element={<EditData />} />
          <Route path="*" element={<p>Page Not Found</p>} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
