import {
  Dashboard,
  Logout,
  ProductionQuantityLimits,
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const signOut = async () => {
    await Auth.signOut({ global: true })
      .then((res) => {
        navigate("/login");
      })
      .then((res) => console.log(res))
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const heading =
    splitLocation[1].charAt(0).toUpperCase() + splitLocation[1].slice(1);

  document.title = heading
    ? heading + " | World Trade X"
    : "Dashboard | World Trade X";
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "60px",
              gap: "1rem",
            }}
          >
            <img src="/images/logo.png" alt="logo" height="40px" />
            <h4>World Trade X</h4>
          </div>
          <IconButton
            sx={{
              ...(!open && { display: "none" }),
            }}
            onClick={handleDrawerClose}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon style={{ color: "black" }} />
            )}
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 1,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link to="/">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={splitLocation[1] === "" ? "active" : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                className={splitLocation[1] === "" ? "" : "hover-menu"}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Dashboard
                    style={{
                      color: splitLocation[1] === "" ? "white" : "black",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            className={splitLocation[1].includes("products") ? "active" : ""}
            onClick={() => {
              navigate(`/products`);
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={
                splitLocation[1].includes("products") ? "" : "hover-menu"
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ProductionQuantityLimits
                  style={{
                    color: splitLocation[1].includes("products")
                      ? "white"
                      : "black",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Product Management"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <Link to="/orders">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={splitLocation.includes("orders") ? "active" : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                className={splitLocation.includes("orders") ? "" : "hover-menu"}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ListAltIcon
                    style={{
                      color: splitLocation.includes("orders")
                        ? "white"
                        : "black",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Order Management"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>

          <Link to="/users">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={splitLocation.includes("users") ? "active" : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                className={splitLocation.includes("users") ? "" : "hover-menu"}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PeopleIcon
                    style={{
                      color: splitLocation.includes("users")
                        ? "white"
                        : "black",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="User Management"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
          {/* <Link to="/rfq">
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={splitLocation.includes("rfq") ? "active" : ""}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                className={splitLocation.includes("rfq") ? "" : "hover-menu"}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MenuBookIcon
                    style={{
                      color: splitLocation.includes("rfq") ? "white" : "black",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="RFQ Management"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </Link> */}
        </List>
        <Divider />

        <Link to="/profile">
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            className={splitLocation[1] === "profile" ? "active" : ""}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={splitLocation.includes("profile") ? "" : "hover-menu"}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ManageAccountsIcon
                  style={{
                    color: splitLocation[1] === "profile" ? "white" : "black",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Link>

        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={signOut}
            className="hover-menu"
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Logout style={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Drawer>
      <Box component="main" className="main-container">
        <Outlet />
      </Box>
    </Box>
  );
}
