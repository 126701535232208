import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CTableRow, NumberFormat } from "./styles";

const headCells = [
  {
    id: "date",
    disablePadding: true,
    label: "Date",
  },
  {
    id: "orderId",
    disablePadding: false,
    label: "Order ID",
  },
  {
    id: "buyername",
    disablePadding: false,
    label: "Buyer Name",
  },
  // {
  //   id: "qty",
  //   disablePadding: false,
  //   label: "Qty",
  // },

  {
    id: "amount",
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
];

export default function OrdersTable({ orders }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (orders?.length === 0)
    return (
      <div className="no-items">
        <img src="/images/noProds.svg" alt="" width="300px" />
        <h2>No Orders</h2>
      </div>
    );

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ borderRadius: ".3rem" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead sx={{ background: "lightgray" }}>
              <CTableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.id === "Action" ? "right" : "left"}
                    padding="normal"
                  >
                    <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {headCell.label}
                    </p>
                  </TableCell>
                ))}
              </CTableRow>
            </TableHead>
            <TableBody>
              {orders
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      sx={{ cursor: "pointer" }}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => navigate(`${row?.id}`)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {new Date(row?.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell style={{ width: 340 }}>{row?.code}</TableCell>
                      <TableCell>{row?.buyer?.name}</TableCell>
                      <TableCell>
                        $
                        <NumberFormat
                          value={row?.total?.toFixed(2)}
                          allowLeadingZeros
                          thousandSeparator=","
                        />
                      </TableCell>
                      <TableCell>{row?.orderStatus}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orders?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Box>
  );
}
