import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";

const steps = [
  {
    label: "Order Accepted",
    description: `22/12/22 12:21:10`,
  },
  {
    label: "Payment Processed",
    description: "23/12/22 09:02:10",
  },
  {
    label: "In-Packing",
    description: "23/12/22 09:02:10",
  },
  {
    label: "Ready to Dispatch",
    description: "23/12/22 09:02:10",
  },
  {
    label: "In-Transit",
    description: "23/12/22 09:02:10",
  },
  {
    label: "Out for Delivery",
    description: "23/12/22 09:02:10",
  },
  {
    label: "Delivered",
    description: "23/12/22 09:02:10",
  },
];

export default function OrderStatus() {
  const [activeStep, setActiveStep] = React.useState(2);

  return (
    <Box sx={{ maxWidth: 400, padding: "1rem" }}>
      <h2 className="header">Tracking Order #r5347599-q3456</h2>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <h3>{step.label}</h3>
            </StepLabel>
            {activeStep >= index && (
              <Typography sx={{ marginLeft: "2.1rem" }}>
                {step.description}
              </Typography>
            )}

            {/* <StepContent>
            </StepContent> */}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
