import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Image from "./Image";
import { CTableRow, NumberFormat } from "./styles";

export default function ProductTable({
  items,
  changeProdState,
  handleDelete,
  value,
  setPage,
  page,
  disabled,
}) {
  const navigate = useNavigate();
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currItem, setCurrItem] = useState(null);
  const [prodID, setProdID] = useState(null);

  const handleClick = (event, id) => {
    setCurrItem(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCurrItem(null);
  };

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const user = useSelector((state) => state.userInfo?.payload);

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ width: "100%", mb: 2 }}>
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "lightgray" }}>
              <CTableRow>
                <TableCell className="th">Product Description</TableCell>
                <TableCell className="th">Category</TableCell>
                <TableCell className="th">Sub Category</TableCell>
                <TableCell className="th">Price</TableCell>
                <TableCell className="th">Added on</TableCell>
                <TableCell className="th" align="center">
                  Status
                </TableCell>
                <TableCell className="th" align="right">
                  Action
                </TableCell>
              </CTableRow>
            </TableHead>
            <TableBody>
              {items &&
                items.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Image
                          link={row.image ? row.image : "/images/dummy.png"}
                        />
                        <div>
                          <Tooltip title={row.description}>
                            <h3>{row.name}</h3>
                          </Tooltip>
                          {/* <p title={row.description}>
                            {row.description.slice(0, 30)}..
                          </p> */}
                        </div>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row.productCategory?.name || "Other"}
                    </TableCell>
                    <TableCell>
                      {row.productSubCategory?.name || "Other"}
                    </TableCell>
                    <TableCell>
                      $
                      <NumberFormat
                        value={
                          row.unitPrice === 0
                            ? JSON.parse(row?.tierPrice)[0]?.price.toFixed(2)
                            : row.unitPrice.toFixed(2)
                        }
                        allowLeadingZeros
                        thousandSeparator=","
                      />
                    </TableCell>
                    <TableCell>{row.createdAt.slice(0, 10)}</TableCell>
                    <TableCell align="center">
                      <p
                        style={{ margin: "auto" }}
                        className={
                          row.active ? "capsule" : "capsule capsule-inactive"
                        }
                      >
                        {row.active ? "Active" : "Inactive"}
                      </p>
                    </TableCell>

                    {user?.role !== "VIEWER" && (
                      <TableCell align="right">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={
                            currItem === row.id ? "long-menu" : undefined
                          }
                          aria-expanded={
                            currItem === row.id ? "true" : undefined
                          }
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(e, row.id);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={currItem === row.id ? true : false}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "10rem",
                              boxShadow: "1px 1px 2px lightgray",
                            },
                          }}
                        >
                          {value === "one" && row?.verified !== false && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                changeProdState(currItem);
                              }}
                            >
                              {row.active ? "Make Inactive" : "Make Active"}
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() => {
                              navigate(`/products/view/${row.id}`);
                            }}
                          >
                            View
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              navigate(`/products/edit/${row.id}`);
                            }}
                          >
                            Edit
                          </MenuItem>
                          {/* <MenuItem
                            onClick={() => {
                              setOpen(true);
                              setProdID(row.id);
                              handleClose();
                            }}
                          >
                            Delete
                          </MenuItem> */}
                        </Menu>
                      </TableCell>
                    )}
                    {user?.role === "VIEWER" && (
                      <TableCell align="right">
                        <Button
                          onClick={() => {
                            navigate(`/products/view/${row.id}`);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {" Do you really want to delete this Product ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              All the active order associated with this product will be
              affected!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setOpen(false)}>
              No
            </Button>
            <Button
              onClick={() => {
                handleDelete(prodID);
                setOpen(false);
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Box>
            <Button
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 0}
            >
              Prev
            </Button>
            <Button
              onClick={() => setPage((prev) => prev + 1)}
              disabled={disabled}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
