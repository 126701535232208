import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function Terms({ setFieldValue }) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      I agree with the{" "}
      <b
        onClick={handleClickOpen("paper")}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          marginLeft: ".4rem",
        }}
      >
        Terms and Conditions
      </b>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Read the Terms</DialogTitle>
        <DialogContent>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor,
            voluptate perferendis iure rerum repellat nobis necessitatibus
            adipisci neque non facilis repudiandae voluptates!
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={() => {
              handleClose();
              setFieldValue("agreement", false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              handleClose();
              setFieldValue("agreement", true);
            }}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
