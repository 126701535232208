import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Divider, MenuItem, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { API, Auth, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UsersTable from "../components/UsersTable";
import { Label, SubmitBtn, errorMsg, modalStyle } from "../components/styles";
import { createSellerUser, createUser } from "../graphql/mutations";

const Users = () => {
  const [open, setOpen] = React.useState(false);
  const seller = useSelector((state) => state.sellerInfo.payload);
  const currentUser = useSelector((state) => state.userInfo?.payload);
  const [roles, setRoles] = useState([]);
  const [err, setErr] = useState(null);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateData, setUpdateData] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
  });

  // QUERIES
  const listSellerUsers = /* GraphQL */ `
    query ListSellerUsers(
      $filter: ModelSellerUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listSellerUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          sellerId
          seller {
            id
            name
            profile
            image
            taxId
            active
            verified
            phone
            email
            website
            address
            attributes
            images
            documents
            rating
            bank
            products {
              items {
                name
                active
                createdAt
                description
                id
                image
                images
                listPrice
                verified
                unitType
                unitPrice
                tierPrice
                productCategory {
                  name
                  productSubCategories {
                    items {
                      name
                    }
                  }
                }
              }
              nextToken
            }
            orders {
              items {
                id
              }
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          userId
          role
          createdAt
          user {
            name
            email
            id
            phone
            role
          }
          updatedAt
        }
        nextToken
      }
    }
  `;
  const listUsers = /* GraphQL */ `
    query ListUsers(
      $filter: ModelUserFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;
  // QUERIES END

  // MUTATIONS
  const createSellerUser = /* GraphQL */ `
    mutation CreateSellerUser(
      $input: CreateSellerUserInput!
      $condition: ModelSellerUserConditionInput
    ) {
      createSellerUser(input: $input, condition: $condition) {
        id
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        userId
        user {
          id
          name
          photo
          phone
          email
          role
          deleted
          createdAt
          updatedAt
        }
        role
        createdAt
        updatedAt
      }
    }
  `;
  const createUser = /* GraphQL */ `
    mutation CreateUser(
      $input: CreateUserInput!
      $condition: ModelUserConditionInput
    ) {
      createUser(input: $input, condition: $condition) {
        id
        name
        photo
        phone
        email
        role
        deleted
        buyers {
          nextToken
        }
        sellers {
          nextToken
        }
        devices {
          nextToken
        }
        notifications {
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  `;

  // MUTATIONS END
  function updateUserInput(e, field) {
    let temp = { ...user };
    temp[field] = e.target.value;
    setUser(temp);
  }

  function handleForm(e) {
    e.preventDefault();
    const { name, email, role } = user;
    Auth.signUp({
      username: email,
      password: "Password@1",
      attributes: { name },
    })
      .then((cognitoData) => {
        API.graphql({
          query: createUser,
          variables: {
            input: {
              id: cognitoData.userSub,
              name,
              email,
            },
          },
        })
          .then((userData) =>
            API.graphql({
              query: createSellerUser,
              variables: {
                input: {
                  sellerId: seller?.id,
                  userId: userData?.data?.createUser?.id,
                  role,
                },
              },
            })
              .then(() => {
                setUpdateData(new Date().getMilliseconds());
                setOpen(false);
              })
              .catch((e) => console.log(e, "seller user failed"))
          )
          .catch((e) => {
            console.log(e, "user failed");
            // setErr(e.message);
          });
      })
      .catch((e) => {
        console.log(e, "cognito failed");
        API.graphql(
          graphqlOperation(listUsers, {
            filter: { email: { eq: email } },
          })
        ).then((userData) => {
          console.log(userData.data.listUsers, "user found");
          API.graphql(
            graphqlOperation(listSellerUsers, {
              filter: { userId: { eq: userData.data.listUsers.items[0].id } },
            })
          ).then((res) => {
            console.log(res, "seller user found");
            // if (res.data.listSellerUsers.items.length === 0) {
            API.graphql({
              query: createSellerUser,
              variables: {
                input: {
                  sellerId: seller?.id,
                  userId: userData.data.listUsers.items[0].id,
                  role,
                },
              },
            })
              .then((res) => {
                setUpdateData(new Date().getMilliseconds());
                setOpen(false);
                console.log(res, "seller user created");
              })
              .catch((e) => console.log(e, "seller user failed"));
            // } else {
            // setErr("Seller user already exists!");
            // }
          });
        });
      });
  }

  function handleSearch() {
    let temp = [...tempUsers];
    temp = temp.filter((item) =>
      item.user.name.toLowerCase().startsWith(search.toLowerCase())
    );
    setUsers(temp);
  }

  useEffect(() => {
    const getParentEnum = `
    query GetParentEnum {
     __type(name: "Role") {
     enumValues {
     name
    }
    }
     }
    `;

    API.graphql(graphqlOperation(getParentEnum)).then((response) => {
      let temp = response.data.__type.enumValues.map(
        (enumValue) => enumValue.name
      );
      setRoles(temp);
    });
  }, []);

  useEffect(() => {
    setUser({
      name: "",
      email: "",
      role: "",
    });
  }, [open]);

  useEffect(() => {
    API.graphql(
      graphqlOperation(listSellerUsers, {
        filter: { sellerId: { eq: seller?.id }, id: { ne: currentUser?.id } },
      })
    ).then((res) => {
      setLoading(false);
      setUsers(res?.data?.listSellerUsers?.items);
      setTempUsers(res?.data?.listSellerUsers?.items);
    });
  }, [updateData]);

  if (loading) return <CircularProgress className="loader" />;

  return (
    <div className="base-container">
      <div className="header-container">
        <h1 className="header">Users</h1>
        {currentUser?.role === "OWNER" && (
          <SubmitBtn onClick={() => setOpen(true)}>Invite Users</SubmitBtn>
        )}
      </div>
      <Divider />
      <div className="filter-container">
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          size="small"
          sx={{ width: "20%" }}
          placeholder="Search Users By Name"
        />
        <SubmitBtn onClick={handleSearch}>Search</SubmitBtn>
        <SubmitBtn
          onClick={() => {
            setUsers(tempUsers);
            setSearch("");
          }}
        >
          Reset
        </SubmitBtn>
      </div>

      <UsersTable users={users} setUpdateData={setUpdateData} />

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={(e) => handleForm(e)}>
          <Box sx={modalStyle}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <h2 style={{ marginBottom: ".7rem" }}>Invite User </h2>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ cursor: "pointer" }}
              />
            </Box>
            <Divider style={{ marginBottom: ".7rem" }} />
            <Label>Name*</Label>
            <TextField
              size="small"
              placeholder="e.g John Doe"
              required
              value={user.name}
              onChange={(e) => {
                updateUserInput(e, "name");
              }}
            />
            <Label>Email*</Label>
            <TextField
              size="small"
              placeholder="e.g john@wtx.com"
              required
              type="email"
              value={user.email}
              onChange={(e) => {
                updateUserInput(e, "email");
              }}
            />
            <Label>Role*</Label>
            <TextField
              required
              select
              size="small"
              label="Choose a role"
              value={user.role}
              onChange={(e) => {
                updateUserInput(e, "role");
              }}
            >
              {roles.map((role, i) => (
                <MenuItem key={i} value={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>
            {err && <p style={errorMsg}>{err}</p>}
            <div style={{ marginTop: "1rem" }}>
              <SubmitBtn type="submit" style={{ float: "right" }}>
                Invite Users
              </SubmitBtn>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
};

export default Users;
