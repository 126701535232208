import styled from "@emotion/styled";
import {
  Box,
  Card,
  Chip,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

export const Label = styled("label")({
  fontWeight: "bold",
  fontSize: "1.1rem",
});
export const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "3rem",
  marginTop: "2rem",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
});
export const SubmitBtn = styled("button")({
  backgroundColor: "#183B59",
  border: "none",
  fontWeight: "bold",
  padding: "10px",
  fontFamily: "'Inter',sans-serif",
  textDecoration: "",
  color: "white",
  cursor: "pointer",
  minWidth: "70px",
  borderRadius: ".3rem",
  "&:hover": {
    backgroundColor: "#265089",
  },
});
export const STableHead = styled(TableCell)({
  fontSize: "1rem",
  fontWeight: "600",
});
export const StyledTableRow = styled(TableRow)({
  "&:hover": {
    background: "#f0f0f0",
    cursor: "pointer",
  },
});

export const CustomDiv = styled("div")({
  borderRadius: ".4rem",
  border: "2px solid lightgray",
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: ".7rem",
  gap: "1rem",
  overflowX: "scroll",
  overflow: "hidden",
  flexWrap: "wrap",
});

export const modalStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  padding: "1rem",
  gap: ".7rem",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  background: "white",
  borderRadius: ".3rem",
  boxShadow: 10,
  p: 2,
};

export const textStyle = {
  textAlign: "center",
};
export const formStyle = {
  display: "flex",
  flexDirection: "column",
  gap: ".6rem",
};
export const errorMsg = {
  color: "red",
};

export const textFieldStyle = {
  borderRadius: ".3rem",
  border: "1px solid lightgrey",
  fontSize: "1rem",
  padding: "1rem",
  outlineColor: "#183B59",
};
export const dateFieldStyle = {
  borderRadius: ".4rem",
  border: "1px solid lightgrey",
  fontSize: "1rem",
  padding: ".6rem 1rem",
  outlineColor: "#183B59",
};
export const otpStyle = {
  borderRadius: ".4rem",
  border: "1px solid lightgray",
  fontSize: "1rem",
  padding: ".65rem",
  outlineColor: "#183B59",
};
export const numStyle = {
  borderRadius: ".4rem",
  border: "1px solid lightgray",
  fontSize: "1rem",
  padding: ".65rem",
  height: "3.7rem",
  outlineColor: "#183B59",
};
export const UploadDiv = styled("div")({
  padding: ".4rem",
  display: "flex",
  borderRadius: ".4rem",
  marginBottom: ".1rem",
  position: "relative",
});
export const clrIcon = {
  cursor: "pointer",
  position: "absolute",
  right: "0",
  top: "-.3rem",
  background: "#dfdfdf",
  borderRadius: "50%",
};
export const OrderCard = styled(Card)({
  minHeight: "10rem",
  padding: "1rem",
  boxShadow: "0 0.75rem 1.5rem rgba(18,38,63,.03)",
  border: "1px solid #edf2f9",
});
export const ODiv = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
});
export const MoreBtn = styled("button")({
  border: "none",
  background: "none",
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  float: "right",
  cursor: "pointer",
  fontWeight: "bold",
  color: "#265075",
});

export const CTableRow = styled(TableRow)({
  background: "#ecf0f4",
});
export const STableContainer = styled(TableContainer)({
  borderRadius: ".2rem",
});

export const GridDiv = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: "1rem",
});
export const DashDiv = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 1rem",
  textAlign: "right",
});
export const DashBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const SellerCard = styled("div")({
  padding: "3rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: "60%",
  boxShadow: "0rem 0.75rem 1.5rem 4px #12243f24",
  maxHeight: "6rem",
  minWidth: "300px",
});
export const authCard = {
  display: "flex",
  flexDirection: "column",
  width: "24%",
  maxWidth: "500px",
  minWidth: "400px",
  py: 3,
  px: 3,
  gap: 2,
  boxShadow: "none",
  borderRadius: "15px",
  bgcolor: "#fbfb`fb",
};
export const keywordInput = {
  flexGrow: 1,
  outline: "none",
  border: "none",
  fontSize: "1rem",
  padding: ".5rem",
};
export const keywordDiv = {
  fontSize: "1rem",
  display: "flex",
  gap: ".4rem",
  borderRadius: "222222px",
  padding: ".3rem .6rem",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid gray",
};
export const signUpStyle = {
  display: "flex",
  flexDirection: "column",
  width: "30%",
  maxWidth: "500px",
  minWidth: "400px",
  py: 3,
  px: 3,
  gap: 2,
  boxShadow: "none",
  borderRadius: "15px",
  mx: "auto",
  bgcolor: "#fbfbfb",
};
export const ChipStyle = styled(Chip)({
  bgcolor: "#cdd4da",
  cursor: "pointer",
});

export const NumberFormat = styled(NumericFormat)({
  border: "none",
  pointerEvents: "none",
  background: "none",
});
