import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, useParams } from "react-router-dom";
import BasicTabs from "../components/Tabs";
import { Label } from "../components/styles";

export default function PreviewProduct() {
  const { ID } = useParams();
  const [prodData, setProdData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [images, setImages] = useState([]);
  const [tierPrice, setTierPrice] = useState([]);

  // QUERIES
  const getProduct = /* GraphQL */ `
    query GetProduct($id: ID!) {
      getProduct(id: $id) {
        id
        name
        description
        image
        images
        documents
        listPrice
        unitPrice
        tierPrice
        unitType
        attributes
        active
        verified
        taxExempt
        keywords
        leadTime
        rating
        stock
        sellerId
        seller {
          id
          name
          profile
          image
          taxId
          active
          verified
          phone
          email
          website
          address
          attributes
          images
          documents
          rating
          bank
          createdAt
          updatedAt
        }
        createdAt
        productCategoryId
        productCategory {
          id
          productType
          name
          image
          active
          createdAt
          updatedAt
        }
        productSubCategoryId
        productSubCategory {
          id
          name
          image
          active
          productCategoryId
          createdAt
          updatedAt
        }
        createdAt
        feedbacks {
          nextToken
        }
        updatedAt
      }
    }
  `;
  // QUERIES END
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    API.graphql({
      query: getProduct,
      variables: {
        id: ID,
      },
    }).then((res) => {
      setProdData(res.data.getProduct);
      setLoading(false);
      setImages(JSON.parse(res.data.getProduct?.images));
      setTierPrice(JSON.parse(res.data.getProduct?.tierPrice));
    });
  }, []);

  if (loading) {
    return <CircularProgress className="loader" />;
  }

  return (
    <div className="base-container">
      <div className="header-container">
        <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
          <Link to="/products">
            <ArrowBackIosIcon sx={{ fontSize: "medium", marginTop: ".3rem" }} />
          </Link>
          <h1 className="header">Product Details</h1>
        </div>
      </div>
      <Box sx={{ marginTop: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={4000}
              showThumbs={false}
            >
              <img
                src={prodData?.image}
                alt="hero_img"
                style={{
                  objectFit: "cover",
                  height: "400px",
                  width: "100%",
                }}
              />
              {images?.map((item, i) => (
                <div key={i} style={{ height: "400px" }}>
                  {item?.type == "video" ? (
                    <video width={700} height={400} controls>
                      <source src={item?.url} />{" "}
                    </video>
                  ) : (
                    <img
                      src={item?.url}
                      alt="prod images"
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  )}
                </div>
              ))}
            </Carousel>
          </Grid>
          <Grid item xs={6}>
            <h4>{prodData?.name}</h4>
            <p>{prodData.description}</p>

            {prodData?.unitPrice !== 0 && (
              <>
                <h4>Price</h4>
                <p>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(prodData?.unitPrice)}
                </p>
              </>
            )}

            {tierPrice && (
              <Box sx={{ mt: 3 }}>
                <Label>Price Ranges</Label>
                <Box sx={{ mt: 1 }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gap: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <Label>Minimum Units</Label>
                    <Label>Maximum Units</Label>
                    <Label>Actual Price</Label>
                    <Label>Discounted Price</Label>
                  </div>
                  {tierPrice?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        gap: "1rem",
                        marginBottom: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        disabled={true}
                        value={item?.minQty}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              | {prodData?.unitType}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        disabled={true}
                        value={item.maxQty}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              | {prodData?.unitType}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        disabled={true}
                        value={item?.price}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">| $</InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        disabled={true}
                        value={item?.discountPrice}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">| $</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  ))}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        {/* <AppBar
          position="static"
          sx={{ borderRadius: "1rem", boxShadow: "none", mt: 1 }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              width: "100%",
              background: "#ecf0f4",
              color: "black",
              padding: ".4rem",
              borderRadius: ".6rem",
            }}
          >
            <Tab
              label="Product Details"
              {...a11yProps(0)}
              sx={{
                background: value === 0 && "#183B59",
                color: value === 0 && "white",
                borderRadius: value === 0 && ".4rem",
                transition: "all .2s ease-in-out",
                marginRight: ".2rem",
              }}
            />
            <Tab
              label="Seller Details"
              {...a11yProps(1)}
              sx={{
                background: value === 1 && "#183B59",
                color: value === 1 && "white",
                borderRadius: value === 1 && ".4rem",
                transition: "all .2s ease-in-out",
              }}
            />
          </Tabs>
        </AppBar> */}

        <BasicTabs items={prodData} />
      </Box>
    </div>
  );
}
