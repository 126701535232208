import { Box, MenuItem, TextField } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { client } from "../App";
import { ChipStyle, SubmitBtn, dateFieldStyle } from "./styles";

// ChartJS.register(zoomPlugin);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const types = [
  "Orders",
  "Products",
  "Refund Request",
  "Cancelled Orders",
  "Return Request",
  "Gross Sales",
];

export default function Chart() {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const sellerId = useSelector((state) => state?.sellerInfo?.payload?.id);

  const [startDate, setStartDate] = useState(new Date(oneMonthAgo));
  const [endDate, setEndDate] = useState(new Date());

  const [filterType, setFilterType] = useState("Orders");
  const [timeFrame, setTimeFrame] = useState("");

  const [searchParam, setSearchParam] = useState("order");

  const [dates, setDates] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [backup, setBackUp] = useState([]);

  const data = {
    labels: chartData.map((item) => item.date),
    datasets: [
      {
        label: filterType + " (" + timeFrame + ") ",
        data: chartData.map((item) => item.count),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  function getDaysRange() {
    setTimeFrame("Daily");
    const dateStrings = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      const day = ("0" + currentDate.getDate()).slice(-2);
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const year = currentDate.getFullYear().toString().substr(-2);
      dateStrings.push(`${day}-${month}-${year}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setDates(dateStrings);
  }

  function initialRange() {
    setTimeFrame("Daily");
    let dateArray = [];

    for (let i = 30; i >= 0; i--) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear().toString().substr(-2);
      let formattedDate = `${day}-${month}-${year}`;
      dateArray.push(formattedDate);
    }

    setDates(dateArray);
  }

  function getData() {
    let dateArray = [];

    for (let i = 30; i >= 0; i--) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear().toString().substr(-2);
      let formattedDate = `${day}-${month}-${year}`;
      dateArray.push(formattedDate);
    }

    client
      .initIndex(searchParam)
      .search("", {
        facetFilters: [`seller.id:${sellerId}`],
        numericFilters: [
          `modified_createdAt >= ${Math.floor(Date.parse(startDate) / 1000)}`,
          `modified_createdAt <= ${Math.floor(Date.parse(endDate) / 1000)}`,
        ],
      })
      .then((res) => {
        let data = res.hits;
        if (filterType === "Cancelled Orders")
          data = res.hits.filter((item) => item.orderStatus === "CANCELLED");

        const ordersByDay = data?.reduce((acc, order) => {
          const dateStr = order.createdAt.slice(0, 10);
          const date = new Date(dateStr);
          const formattedDate = date
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })
            .replace(/\//g, "-");

          if (acc[formattedDate]) {
            acc[formattedDate]++;
          } else {
            acc[formattedDate] = 1;
          }
          return acc;
        }, {});

        if (dates.length !== 0) {
          dates.forEach((date) => {
            if (!ordersByDay[date]) {
              ordersByDay[date] = 0;
            }
          });
        } else {
          dateArray.forEach((date) => {
            if (!ordersByDay[date]) {
              ordersByDay[date] = 0;
            }
          });
        }

        let ordersByDayArr = Object.keys(ordersByDay).map((dateStr) => ({
          date: dateStr,
          count: ordersByDay[dateStr],
        }));
        let x = ordersByDayArr.sort((a, b) => {
          const dateA = new Date(`20${a.date.split("-").reverse().join("-")}`);
          const dateB = new Date(`20${b.date.split("-").reverse().join("-")}`);
          return dateA - dateB;
        });
        setChartData(x);
        setBackUp(x);
      });
  }

  function getDataByWeek() {
    setTimeFrame("Week");
    const result = [];
    const data = backup;

    for (let i = 0; i < data.length; i += 7) {
      let count = 0;
      const startDateStr = data[i].date;
      const startDate = new Date(
        `20${startDateStr.slice(-2)}-${startDateStr.slice(
          3,
          5
        )}-${startDateStr.slice(0, 2)}`
      );

      for (let j = i; j < i + 7 && j < data.length; j++) {
        count += data[j].count;
      }

      result.push({ date: startDate.toISOString().slice(0, 10), count });
    }

    setChartData(result);
  }

  function getDataByMonth() {
    setTimeFrame("Month");
    const result = [];
    let data = backup;
    for (let i = 0; i < data.length; i++) {
      const currentDateStr = data[i].date;
      const currentDate = new Date(
        `20${currentDateStr.slice(-2)}-${currentDateStr.slice(
          3,
          5
        )}-${currentDateStr.slice(0, 2)}`
      );
      const currentMonth = currentDate.toLocaleString("default", {
        month: "long",
      });
      let count = data[i].count;

      // Check if the month exists in the result array
      const monthIndex = result.findIndex((item) => item.date === currentMonth);
      if (monthIndex !== -1) {
        result[monthIndex].count += count;
      } else {
        result.push({ date: currentMonth, count });
      }
    }
    setChartData(result);
  }

  function getDataByYear() {
    setTimeFrame("Year");
    const yearData = {};
    let data = backup;
    data.forEach((item) => {
      const year = item.date.split("-")[2];
      if (yearData[year]) {
        yearData[year].count += item.count;
      } else {
        yearData[year] = { date: year, count: item.count };
      }
    });

    const result = Object.values(yearData);
    setChartData(result);
  }

  useEffect(() => {
    initialRange();
  }, []);

  useEffect(() => {
    getData();
  }, [searchParam, dates, sellerId, filterType]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          mt: 3,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <h4>Filter By : </h4>
          <TextField
            select
            size="small"
            value={filterType}
            onChange={(e) => {
              setFilterType(e.target.value);
              e.target.value === "Products" && setSearchParam("product");
              e.target.value === "Orders" && setSearchParam("order");
            }}
            sx={{ minWidth: "200px" }}
          >
            {types.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getDaysRange();
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <h4>Date Range : </h4>
            <input
              required
              type="date"
              size="small"
              value={startDate}
              style={dateFieldStyle}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              required
              type="date"
              size="small"
              min={startDate}
              value={endDate}
              style={dateFieldStyle}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <SubmitBtn type="submit">Submit</SubmitBtn>
            <SubmitBtn
              type="reset"
              onClick={() => {
                initialRange();
                setStartDate(new Date(oneMonthAgo));
                setEndDate(new Date());
              }}
            >
              Reset
            </SubmitBtn>
          </Box>
        </form>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          mt: 4,
          pointerEvents: (!startDate || !endDate) && "none",
        }}
      >
        <ChipStyle label={"Daily"} onClick={getDaysRange} />
        <ChipStyle label={"Weekly"} onClick={getDataByWeek} />
        <ChipStyle label={"Monthly"} onClick={getDataByMonth} />
        <ChipStyle label={"Yearly"} onClick={getDataByYear} />
      </Box>

      <Box sx={{ height: "40vh", width: "80vw", overflowX: "auto" }}>
        <Line options={options} data={data} />
      </Box>
    </>
  );
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
  plugins: {
    zoom: {
      zoom: {
        pan: {
          enabled: true,
        },
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: "x",
      },
    },
  },
  legend: {
    position: "top",
  },
  title: {
    display: false,
  },
};
