import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export default function Image({ images }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: 400, sm: 590, md: 800, lg: 1000, xl: 1400 },
        bgcolor: "background.paper",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {images.map((item, i) => (
          <Tab
            key={i}
            label={
              <img
                src={item?.url}
                alt="cert_image"
                height={"300px"}
                style={{}}
              />
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}
